import { instance } from 'index';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function ContactForm() {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    is_active: true,
  });

  const handleInputChange = (event) => {
    setFormData((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  }

  const sendFormData = async () => {
    try {
      setIsRequestSent(true);

      const result = await instance.post('contact/', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      if (result?.status === 201) {
        toast.success('Your Form Submitted successfully!');
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: '',
          is_active: true,
        });
        setIsRequestSent(false);
      }
    } catch (error) {
      toast.error('Something Went Wrong. Please try');
      setIsRequestSent(false);
      console.log({ error });
    }
  }

  const handleFormSubmission = (e) => {
    e.preventDefault();
    sendFormData();
  }

  return (
    <>
      <div className='contact-information padding-20'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-xl-6'>
              <div className='contact-form '>
                <h3>Have Any Questions</h3>
                <form onSubmit={handleFormSubmission}>
                  <div className='row form-container'>
                    <div className='col-12'>
                      <input
                        required
                        type='text'
                        name='name'
                        value={formData?.name}
                        placeholder='Enter your name'
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-xl-6'>
                      <input
                        required
                        type='email'
                        name='email'
                        value={formData?.email}
                        placeholder='Enter your email'
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-xl-6'>
                      <input
                        required
                        type='text'
                        name='subject'
                        value={formData?.subject}
                        onChange={handleInputChange}
                        placeholder='Subject'
                      />
                    </div>
                    <div className='col-12'>
                      <textarea
                        required
                        name='message'
                        cols={30}
                        rows={10}
                        placeholder='Your message'
                        onChange={handleInputChange}
                        value={formData?.message}
                      />
                    </div>

                    <div className='cmn-btn'>
                      <button className='my-2 border' disabled={isRequestSent}>
                        {isRequestSent ? 'Submitting' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
