/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import { Link, useRouteMatch } from 'react-router-dom'
import ColumnChart from './ColumnChart'
import JobAnalysisChart from './JobAnalysisChart'
import ApplicationChart from './ApplicationChart'

const HrDashbord = () => {
	const { path } = useRouteMatch()
	// const {access} = useSelector((state) => state.auth.userInfo)
	// const [empSummary, setEmpSummary] = useState(0)
	// const [projectsLength, setPorjectsLength] = useState(0)
	// const [isLoading, setIsLoading] = useState(false)
	// const [userPayment, setUserPayment] = useState([])
	// const [salary, setSalary] = useState()

	return (
		<>
			<section className='Admindashboard__task__wrapper'>
				<div className='dashboard-container'>
					<div className='row mb-5'>
						<div className='col-12 d-flex align-items-center justify-content-between'>
							<p className='title'>Human Resource Dashboard</p>
						</div>
					</div>
					<div className='row mb-5'>
						<div className='col-md-12'>
							<Link to={`${path}/event-detail`}>
								<div className='dashboardCard'>
									<div className=''>
										<p className=''>
										Event Analysis 
										</p>
									</div>
									<ColumnChart/>
								</div>
							</Link>
						</div>
					</div>
					<div className=' mb-5'>
						<div className=' mb-5'>
							<Link to={`${path}/jobAnalysis`}>
								<div className='dashboardCard'>
									<div className=''>
										<p className=''>
										Job Analysis 
										</p>
									</div>
									<JobAnalysisChart/>
								</div>
							</Link>
						</div>
						<div className=''>
							<Link to={`${path}/jobAnalysis`}>
								<div className='dashboardCard'>
									<div style={{ position: 'relative', width: '100%' }}>
										<p className=''>
										Applications Statics
										</p>
										<ApplicationChart/>
									</div>
									
								</div>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default HrDashbord