/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import { InlineWidget } from "react-calendly";
import { useSelector } from 'react-redux';

function ContactUsArea() {
  const [show, setShow] = useState(false);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const ScheduleMeeting = () =>{
    setShow(true)
    scrollTop()
  }
  return (
		<>
			{show && (
				<React.Fragment>
					<InlineWidget url='https://calendly.com/bugdevhr-n2u/schedule-a-meeting-with-our-team' />
				</React.Fragment>
			)}
			<div className='container padding-20'>
				<div className='row'>
					<div className='col col-xl-6'>
						<div className='title black'>
							<span className='text-danger'>Get in Touch</span>
							<h2 className='text-white'>
								contact us if you have more questions.
							</h2>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-6 col-lg-4 col-xl-4'>
						<div className='office-info'>
							<div className='icon'>
								<i className='bi bi-people'></i>
							</div>
							<h4>Schedule Meeting</h4>
							<button className='meeting-btn' onClick={ScheduleMeeting}>
								Schedule a meeting with our team
							</button>
						</div>
					</div>
					<div className='col-md-6 col-lg-4 col-xl-4'>
						<div className='office-info'>
							<div className='icon'>
								<i className='bi bi-telephone' />
							</div>
							<h4>Phone</h4>
							<a rel='noopener noreferrer' href='tel:+0123456789102'>
							+447440010878
							</a>
							<a rel='noopener noreferrer' href='tel:+923047995329'>
								+923047995329
							</a>
						</div>
					</div>
					<div className='col-md-6 col-lg-4 col-xl-4'>
						<div className='office-info'>
							<div className='icon'>
								<i className='bi bi-envelope' />
							</div>
							<h4>Email</h4>
							{/* <a rel='noopener noreferrer' href='mailto:info@bugdev.co.uk'>
                info@bugdev.co.uk 
              </a> */}
							<a rel='noopener noreferrer' href='mailto:hr@bugdev.co.uk'>
								hr@bugdev.co.uk
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ContactUsArea;
