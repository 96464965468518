import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ScrollButton from './ScrollButton';

function Footer() {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
		<>
			<footer className='padding-20'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8 col-lg-8 col-xl-8'>
							<div className='footer-widget'>
								<div className='footer-logo'>
									{/* <Link onClick={scrollTop} to='/'>
                    <img
                      src={process.env.PUBLIC_URL + '/images/logo.png'}
                      alt='img'
                    />
                  </Link>  */}
									<h1>About us</h1>
									<p>
										We at Bugdev are focused on enhancing your business value
										with the increase in revenue, community trust and efficient
										user-experience with ultimate software and applications.
									</p>
								</div>
								{/* <address>
                  <h4>Office</h4>
                  <p>
                    2 Churchill Court, 435 High Street, Cheltenham, GL50
                    3HU,England, United Kingdom
                  </p>
                </address> */}
								<ul className='social-media-icons'>
									<li>
										<a
											rel='noopener noreferrer'
											href='https://www.facebook.com/profile.php?id=100086140524102'
											target='_blank'
										>
											<i className='fab fa-facebook-f' />
										</a>
									</li>
									<li>
										<a
											rel='noopener noreferrer'
											href='https://twitter.com/Bugdev_it'
											target='_blank'
										>
											<i className='fab fa-twitter' />
										</a>
									</li>

									<li>
										<a
											rel='noopener noreferrer'
											target='_blank'
											href='https://www.linkedin.com/company/bugdev/'
										>
											<i className='fab fa-linkedin' />
										</a>
									</li>
									<li>
										<a
											rel='noopener noreferrer'
											target='_blank'
											href='https://www.instagram.com/bugdeve?igsh=YWlwd2ZvMXJkdWpj'
										>
											<i className='fab fa-instagram' />
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* <div className='col-md-3 col-lg-3 col-xl-3'>
              <div className='footer-widget'>
                <h4>Our Services</h4>
                <ul className='footer-menu'>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Strategy &amp; Research
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Web Solution
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Digital Merketing
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      App Design
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      App Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
						{/* <div className='col-md-3 col-lg-3 col-xl-3'>
              <div className='footer-widget'>
                <h4>Company</h4>
                <ul className='footer-menu'>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/about`}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Project
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/contact`}
                    >
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                      Pricing Plan
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
						{/* <div className='col-md-3 col-lg-3 col-xl-3'>
              <div className='footer-widget'>
                <h4>Contacts</h4>
                <div className='number'>
                  <div className='num-icon'>
                    <i className='fas fa-phone-alt' />
                  </div>
                  <div className='phone'>
                    <a rel='noopener noreferrer' href='tel:+447440010878'>
                      +447440010878
                    </a>
                  </div>
                </div>
                <div className='office-mail'>
                  <div className='mail-icon'>
                    <i className='far fa-envelope' />
                  </div>
                  <div className='email'>
                    <a rel='noopener noreferrer' href='mailto:info@example.com'>
                      hr@bugdev.co.uk
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
					</div>
					<div className='footer-bottom'>
						<div className='row align-items-center'>
							<div className='col-12 col-md-4 col-lg-4 col-xl-5'>
								<div className='copy-txt'>
									<span>
										Copyright 2022 <b>Bugdev</b> | Design By{' '}
										<Link rel='noopener noreferrer' to='/'>
											Bugdev
										</Link>
									</span>
								</div>
							</div>
							<div className='col-12 col-md-8 col-lg-8 col-xl-7'>
								<ul className='footer-bottom-menu'>
									<li>
										<Link onClick={scrollTop} to='/privacy-policy'>
											Privacy Policy
										</Link>
									</li>
									<li>
										<Link onClick={scrollTop} to='/terms-conditions'>
											Terms and condition
										</Link>
									</li>
									<li>
										<Link onClick={scrollTop} to='/support-policy'>
											Support Policy
										</Link>
									</li>
									{/* <li>
                    <Link onClick={scrollTop} to='/'>
                      Terms of Service
                    </Link>
                  </li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div className='scroll-top opacity'>
				<ScrollButton />
			</div>
		</>
	)
}

export default Footer;
