import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { instance } from 'index';
import { useSelector } from 'react-redux';

const RevenueChart = () => {
  const {access} = useSelector((state)=> state.auth.userInfo)
  const [payment, setPayment] = useState([])

  const getRevenue = async () =>{
    try{
      const result = await instance.get('payments/',{
        headers: 
        {
          Authorization : `Bearer ${access}`
        }
      })
      if(result.status === 200)
      {
        const paymentDataResponse = result?.data.results
        const monthlyPayments = {}
        const currentYear  = new Date().getFullYear()

        paymentDataResponse.forEach((payment)=>{
          const createdDate = new Date(payment.created_at)
          const paymentYear = createdDate.getFullYear()
          const currentMonth = new Date().toLocaleString('default', { month: 'long' })

          if(paymentYear === currentYear){
            const month = createdDate.toLocaleString('en-US', { month: 'long' })
						const salary = parseFloat(payment.amount.replace(/[^0-9.-]+/g, ''))

            if (monthlyPayments[month]) {
							monthlyPayments[month] += salary
						} else {
							monthlyPayments[month] = salary
						}
          }
        })

        const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					return {
						month: monthName,
						totalPayment: monthlyPayments[monthName] || 0,
						
					}
				})

				setPayment(allMonths)

      }
    } catch(error){
      console.log({error})
    }
  }

  useEffect(()=>{
    getRevenue();
  },[])

  const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1

  const data = {
    labels: payment.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: 'Total Payment',
        type: 'bar', // Use 'bar' type for this dataset
        data: payment.slice
              (0, currentMonth)
              ?.map((label) => label?.totalPayment),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Profit',
        type: 'line', // Use 'line' type for this dataset
        data: [30, 35, 40, 35, 45, 50, 55, 50, 60, 55, 65, 60],
        borderColor: 'rgba(192, 75, 75, 1)',
        fill: false,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Expected Sales',
        type: 'line', // Use 'line' type for this dataset
        data: [110, 130, 160, 120, 190, 170, 200, 180, 220, 200, 240, 220],
        borderColor: 'rgba(75, 192, 75, 1)',
        fill: false,
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const options = {
    responsive: true, // Enable responsiveness
    maintainAspectRatio: false, // Allow the chart to take the full width/height of the container
    scales: {
      y: [
        {
          type: 'linear',
          position: 'left',
        },
      ],
    },
  };

  return (
    <div className="monthly-sales-chart" style={{ position: 'relative', height: '400px', width: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default RevenueChart;
