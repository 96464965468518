import React from "react";
import { Pie } from "react-chartjs-2";

const MapChart = () => {
  // Sample data for chart
  const data = {
    labels: ["Satisfied", "Neutral", "Dissatisfied"],
    datasets: [
      {
        data: [80, 15, 5], // Replace with your actual data
        backgroundColor: ["green", "yellow", "red"],
      },
    ],
  };

  const options = {
    responsive: true, 
    maintainAspectRatio: false, 
    plugins: {
      legend: {
        position: 'top', 
      },
    },
  };

  return (
    <div className="satisfaction-chart" style={{ position: 'relative', width: '100%', height: '400px' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default MapChart;
