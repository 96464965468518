import React, { useEffect, useState } from "react";
import { instance } from "index";
import { Link, useHistory } from "react-router-dom";
import Loader from "components/common/Loader";
import Loader3 from "components/common/Loader3";

const ProjectDetailPage = ({ message }) => {
  const [project, setProject] = useState();
  const [milestone, setMilestone] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getProject = async (message) => {
    setIsLoading(true);
    try {
      const result = await instance.get(`projects/${message}/`);
      if (result?.status === 200) {
        setIsLoading(false);
        setProject(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const getMilestone = async (message) => {
    setIsLoading(true);
    try {
      const result = await instance.get(`milestones/?project=${message}`);
      if (result?.status === 200) {
        setIsLoading(false);
        setMilestone(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    getProject(message);
    getMilestone(message);
  }, [message]);

  const handleLiveurl = () => {
    window.open(project?.live_url, "_blank");
  };
  const handleGiturl = () => {
    window.open(project?.git_url, "_blank");
  };
  const handleDevurl = () => {
    window.open(project?.develop_url, "_blank");
  };

  return (
    <>
      {isLoading ? (
        <Loader3 />
      ) : (
        <section className="tasks-content">
          <div className="row">
            <div className="col-md-12 col-12 tasks-heading ">
              <div className="d-flex justify-content-between">
                <h4 className="task text-white"> {project?.name} </h4>
                <div className="">
                  <img
                    src={process.env.PUBLIC_URL + "/images/Star_Filled.png"}
                    alt="images"
                    width="40px"
                    height="40px"
                  />
                </div>
              </div>
              <div className="border-bottom bg-light mt-3"></div>
              <div className="tech-card  pt-5">
                <div className="d-flex flex-column mb-2">
                  <div className="event_Tag ">
                    <p>Teach Team</p>
                  </div>
                  <div className="d-flex flex-row mt-2">
                    <img
                      src={process.env.PUBLIC_URL + "/images/user-img.svg"}
                      alt="images"
                      height="25px"
                      width="25px"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/user-img.svg"}
                      alt="images"
                      height="25px"
                      width="25px"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/images/user-img.svg"}
                      alt="images"
                      height="25px"
                      width="25px"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <h5 className="task text-white ">Status</h5>
                  <div className="d-flex flex-row">
                    <span className="Statu"></span>
                    <p className="text-white">{project?.status}</p>
                  </div>
                </div>
                <div className="d-flex flex-column ">
                  <h6 className="text-white ">Live URL</h6>
                  <button className="btn-url " onClick={handleLiveurl}>
                    Open in Browser
                  </button>
                </div>
                <div className="d-flex flex-column ">
                  <h6 className="text-white ">Git URL</h6>
                  <button className="btn-url" onClick={handleGiturl}>
                    Open in Browser
                  </button>
                </div>
                <div className="d-flex flex-column ">
                  <h6 className="text-white ">Develop URL</h6>
                  <button className="btn-url" onClick={handleDevurl}>
                    Open in Browser
                  </button>
                </div>
                <div className="d-flex flex-column ">
                  <h6 className="text-white ">Due Date</h6>
                  <button className="btn-url">{project?.deadline}</button>
                </div>
              </div>
              <div className="border-bottom bg-light mt-3"></div>
              <div className="mt-4">
                <h5 className="text-white">Project Description</h5>
                <p className="text-white">{project?.description}</p>
              </div>

              <div className="mt-5">
                <h5 className="text-white"> Milestones </h5>
                {milestone?.results?.map((data) => {
                  return (
                    <>
                      <div className="d-flex justify-content-between pt-3">
                        <p className="text-white mt-2">{data?.title} </p>
                        <div className="status_btn mb-3">
                          <p className="text-dark mt-1">{data?.status}</p>
                        </div>
                      </div>
                      <div className="border-bottom bg-light mt-0"></div>
                    </>
                  );
                })}
              </div>
            </div>
            <p className="title mt-5">Attechment</p>
            <div className="Attechment_Card mt-3 ">
              {project?.images?.length > 0 ? (
                project?.images?.map((item) => {
                  return (
                    <img
                      src={item?.image}
                      alt="images"
                      height="100px"
                      width="150px"
                      style={{ marginRight: "10px" }}
                    />
                  );
                })
              ) : (
                <p className="text-white">No Attechment</p>
              )}

              {project?.videos.length > 0
                ? project?.videos?.map((item) => {
                    return (
                      <video width="150" height="100" controls>
                        <source src={item?.video} type="video/mp4" />
                      </video>
                    );
                  })
                : ""}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ProjectDetailPage;
