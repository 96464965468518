import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { instance } from 'index';
import { useSelector } from 'react-redux';

const ColumnChart = () => {
  const { access } = useSelector((state) => state.auth.userInfo);
  const [eventsData, setEventsData] = useState([]);
  const getEvents = async () => {
    try {
      const result = await instance.get('events-plans/', {
        headers: {
          'Authorization': `Bearer ${access}`,
        },
      });
  
      if (result?.status === 200) {
        const eventDataResponse = result?.data.results;
        const monthlyEvents = {};
        const currentYear = new Date().getFullYear();
  
        eventDataResponse.forEach((event) => {
          const createdDate = new Date(event.created_at);
          const eventYear = createdDate.getFullYear();
  
          if (eventYear === currentYear) {
            const month = createdDate.toLocaleString('en-US', { month: 'long' });
  
            if (monthlyEvents[month]) {
              monthlyEvents[month]++; // Increment the count for the month
            } else {
              monthlyEvents[month] = 1; // Initialize count to 1 for the month
            }
          }
        });
  
        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = new Date(currentYear, i).toLocaleString('en-US', {
            month: 'long',
          });
          return {
            month: monthName,
            totalEvents: monthlyEvents[monthName] || 0,
          };
        });
  
        setEventsData(allMonths);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  

  useEffect(() => {
    getEvents();
  }, []);

  const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
  const data = {
		labels: eventsData.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: 'Total Events',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: eventsData.slice
              (0, currentMonth)
              ?.map((label) => label?.totalEvents),
      },
    ],
	}

  
  return (
    <div className="column-chart" style={{ position: 'relative', height: '400px', width: '100%' }}>
      <Bar data={data} options={chartOptions} />
    </div>
  );
};

export default ColumnChart;
