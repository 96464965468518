import Loader3 from "components/common/Loader3";
import Table from "components/common/table/Table";
import { instance } from "index";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function MyTime() {
  const { auth } = useSelector((state) => state);
  const { access } = useSelector((state) => state.auth.userInfo);
  const [isClockIn, setIsClockIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userAttendance, setUserAttendance] = useState(null);

  const getUserAttendance = async (userId) => {
    setIsLoading(true);
    try {
      const result = await instance.get(`employee-attendance/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        const UserAttendanceData = result?.data.results.filter(
          (item) => item.user.id === userId
        );
        setUserAttendance(UserAttendanceData);
      }
    } catch (error) {
      setIsLoading(true);
      console.log({ error });
    }
  };

  const checkUserAttendance = async (userId) => {
    try {
      const result = await instance.get(`employee-attendance/${userId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsClockIn(true);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Weekly Hours",
        accessor: "week",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Over Time",
        accessor: "overtime_hours",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Working hours",
        accessor: "number_of_hours",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className="dotDiv d-flex align-items-center justify-content-center w-100"
            >
              <span className="span-badge"></span>
              {cellProps.row.original.number_of_hours}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        filterable: false,
        width: 250,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ""
            ? moment.utc(cell?.value?.toString()).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className={`status ${cellProps.row.original.status} text-capitalize  mx-auto`}
            >
              {cellProps.row.original.status}
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (auth?.userInfo?.user?.id) {
      getUserAttendance(auth?.userInfo?.user?.id);
      checkUserAttendance(auth?.userInfo?.user?.id);
    }
  }, []);

  return (
    <>
      <div className="myTime dashboard_header mb-5">
        <h6 className="text-white tableHeading">My Time Dashbord</h6>
      </div>
      {isLoading ? (
        <Loader3 />
      ) : (
        <div className="row">
          <div className="col-12">
            {userAttendance && (
              <Table
                tableStyles={{ tableLayout: "fixed" }}
                columns={columns}
                initialState={{
                  columnVisibility: { conversion: false },
                }}
                loading={isLoading}
                data={userAttendance}
                total={userAttendance?.results?.length}
                customPageSize={1}
                divclassName="table-responsive mb-1"
                tableclassName="align-middle table-nowrap"
                theadclassName="table-light text-muted"
                getRowId={(row, relativeIndex, parent) => row?.id}
                fetchData={() => console.log("fetchData")}
                pageCount={3}
                currentPage={1}
                isNextPage={2}
                isPrevPage={null}
                nextHandler={() => console.log("Next")}
                prevHandler={() => console.log("Prev")}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
