import { useEffect, useState } from "react";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import { toast } from "react-toastify";
import { instance } from "index";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "redux/auth/authSlice";
import { handleRole } from "contants/helper";
import { useHistory } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLogin, userInfo } = useSelector((state) => state?.auth);
  const [formValues, setFormValues] = useState({ username: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues?.username || !formValues?.password)
      return toast.error("All fields are required!");
    try {
      const result = await instance.post("login/", formValues);
      dispatch(toggleAuth({ isLogin: true, userInfo: result?.data }));
      handleRole(result?.data?.user?.role?.name, history);
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    }
  };

  useEffect(() => {
    if (isLogin) handleRole(userInfo?.user?.role?.name, history);
  }, [isLogin]);

  return (
    <>
      <div className="login-page padding-20">
        <div className="login-box">
          <form onSubmit={handleLogin} className="login-form gy-3">
            <p className="form-title">Welcome back</p>
            <p>Login to the Dashboard</p>
            <div className="d-flex flex-column g-2">
              <input
                type="email"
                name="username"
                required
                placeholder="Email"
                value={formValues?.username}
                onChange={(e) =>
                  setFormValues({ ...formValues, username: e?.target?.value })
                }
              />
              <div className="position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  required
                  placeholder="Password"
                  value={formValues?.password}
                  onChange={(e) =>
                    setFormValues({ ...formValues, password: e?.target?.value })
                  }
                  className="form-control shadow-none"
                  style={{ marginTop: "5vh" }}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    top: "52%",
                    right: "7%",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="d-flex flex-row mt-3">
              <input type="checkbox" id="remeber" name="remeber" />
              <label htmlFor="remeber"> Remember me</label>
              <br></br>
            </div>

            <div className="cmn-btn w-100" style={{ paddingRight: "0px" }}>
              <button className="my-2 w-100">LOGIN</button>
            </div>

            <ul
              className="social-media-icons mt-3 ms-4 "
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/profile.php?id=100086140524102"
                  target="_blank"
                  className="border border-white border-0 bg-transparent"
                >
                  <i className="fab fa-facebook-f " />
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  href="https://twitter.com/Bugdev_it"
                  target="_blank"
                  className="border border-white border-0 bg-transparent"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>

              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/bugdev/posts/?feedView=all&viewAsMember=true"
                  className="border border-white border-0 bg-transparent"
                >
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/p/CqfGFmIDKUD/?utm_source=ig_web_copy_link"
                  className="border border-white border-0 bg-transparent"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
            </ul>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
