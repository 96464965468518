import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { instance } from 'index';
import Lottie from 'lottie-react';
import detail from './detail.json';
import brief from './Clint-brief.json';
import Loader from 'components/common/Loader';

function ProjectProcess() {
	const location = useLocation()
	const projectID = location.state?.message
	const [project, setProject] = useState()
	const [isLoading, setIsLoading] = useState(true)

	const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })

	const getProjectDetail = async (projectID) => {
		try {
			const result = await instance.get(`projects/${projectID}/`)
			if (result?.status === 200) {
				setProject(result?.data)
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(true)
			console.log({ error })
		}
		scrollTop()
	}

	useEffect(() => {
		getProjectDetail(projectID)
	}, [projectID])

	return (
		<>
			<div className='project-process'>
				{isLoading ? (
					<Loader />
				) : (
					<div className='single-step'>
						<div className='row column-directions'>
							<div className='col-md-12 col-xl-6 or2'>
								<div className='step'>
									<h1 className='text-white'>{project?.name}</h1>
									<p className='text-white'>{project?.description}</p>
								</div>
								<div className='cmn-btn '>
									<a href={project?.live_url} className='my-2' target='_blank'>
										Live Preview
									</a>
								</div>
							</div>
							<div className='col-md-12 col-xl-6 or1'>
								<div className='step-img detail-lottie'>
									<Lottie loop={true} animationData={detail} />
								</div>
							</div>
						</div>
					</div>
				)}

				<div className='project-overview '>
					<h3>OVERVIEW</h3>

					<div className='row mt-3 column-directions'>
						<div className='col-md-12 col-lg-7 col-xl-7'>
							<div className='overview-content'>
								<h4>Our Client Work Brief</h4>
								<p className='text-white'>{project?.work_brief}</p>
							</div>
						</div>
						<div className='col-md-12 col-lg-5 col-xl-5'>
							<div className='overview-img brief-lottie'>
								<Lottie loop={true} animationData={brief} />
							</div>
						</div>
					</div>
				</div>
				<div
					className='project-overview margin-40'
					style={{ paddingTop: '40px' }}
				>
					<div>
						<div
							className='col-md-6 col-lg-8 col-xl-12'
							style={{ marginBottom: '40px' }}
						>
							<div className='title'>
								<h4>Technologies We've Used in the Project</h4>
							</div>
						</div>
						<div className='row align-items-center'>
							{project?.technologies?.map((data, index) => {
								return (
									<div
										className='col-sm-6 col-md-6 col-lg-4 col-xl-2'
										key={data?.id}
									>
										<div className='single-client'>
											<img src={data?.icon} alt='images' />
											<div className='client-hover'>
												<a target='_blank'>
													<span>{data?.name}</span>
												</a>
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className='project-overview left margin-40'>
					<h4>PROJECT PROCESS</h4>
					<div className='row'>
						<div className='col-md-6 col-lg-3 col-xl-3 or1'>
							<div className='project-single-step'>
								<h4>01. Brainstorming</h4>
								<div className='project-step-img'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/project/project-single-step-1.jpg'
										}
										alt='images'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6 col-lg-3 col-xl-3 or2'>
							<div className='project-single-step'>
								<h4>02. Wireframe</h4>
								<div className='project-step-img'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/project/project-single-step-2.jpg'
										}
										alt='images'
									/>
								</div>
							</div>
						</div>
						<div className='col-md-6 col-lg-3 col-xl-3 or3'>
							<div className='project-single-step'>
								<h4>03. UI Design</h4>
								<div className='project-step-img'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/project/project-single-step-3.jpg'
										}
										alt='images'
									/>
								</div>
							</div>
						</div>

						<div className='col-md-6 col-lg-3 col-xl-3 or4'>
							<div className='project-single-step'>
								<h4>04. Developing</h4>
								<div className='project-step-img'>
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/project/project-single-step-4.jpg'
										}
										alt='images'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProjectProcess;
