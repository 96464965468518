/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import { instance } from 'index';
import { useSelector } from 'react-redux';

const ProjectSourcesChart = () => {
  const { access } = useSelector((state) => state.auth.userInfo);
  const [projectResource, setProjectResource] = useState();
  const chartRef = useRef(null);
  let chartInstance = useRef(null);

  const getProjectResource = async () => {
    try {
      const result = await instance.get('projects/', {
        headers: {
          Authorization: `Bearer ${access}`
        }
      });

      if (result?.status === 200) {
        const projects = result?.data.results;

        const resourceCounts = {
          linkedin: 0,
          fiver: 0,
          upwork: 0,
          freelance: 0
        };

        projects.forEach((project) => {
          const resource = project.resource;
          const sourceKey = resource;

          if (resourceCounts.hasOwnProperty(sourceKey)) {
            resourceCounts[sourceKey]++;
          }
        });

        setProjectResource(resourceCounts);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (projectResource && chartRef.current) {
      const projectSources = {
        Fiverr: projectResource.fiver,
        Upwork: projectResource.upwork,
        LinkedIn: projectResource.linkedin,
        Freelancer: projectResource.freelance
      };

      const chartData = {
        labels: Object.keys(projectSources),
        datasets: [
          {
            data: Object.values(projectSources),
            backgroundColor: ['blue', 'green', 'orange', 'purple'],
          },
        ],
      };

      const ctx = chartRef.current.getContext('2d');

      // Destroy previous chart instance if it exists to avoid overlay issues
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create a new chart instance
      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: chartData,
        options: {
          responsive: true, // Enable responsiveness
          maintainAspectRatio: false, // Allow the chart to fill the container
          plugins: {
            legend: {
              position: 'top',
            },
          },
        },
      });
    }
  }, [projectResource]);

  useEffect(() => {
    getProjectResource();
  }, []);

  return (
    <div className="satisfaction-chart" style={{ position: 'relative', width: '100%', height: '400px' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ProjectSourcesChart;
