import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";


export default function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const [menus, SetMenus] = useState([
    {
      name: 'Dashbord',
      href: '/account-dashboard',
    },
    {
      name: 'My Time',
      href: '/account-dashboard/my-time',
    },
    {
      name: 'Salaries',
      href: '/account-dashboard/salaries',
    },
    {
      name: 'Request',
      href: '/account-dashboard/request',
    },
    {
      name: 'Employe Time',
      href: '/account-dashboard/over-time',
    },
    {
      name: 'Payments',
      href: '/account-dashboard/payments',
    },
   
  ]);
  const location = useLocation();


  useEffect(() => {
    document.body.classList.add('grey');
    return () => {
      document.body.classList.remove('grey');
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
		<>
<div className={`${isOpen ? "toggle-button2" : "toggle-button"}`} onClick={toggleSidebar} style={{width:"30px",height:"30px"}}>
        <MdOutlineKeyboardArrowRight className={`${!isOpen ? "arrowbtnrotate" : "arrowbtn"}`} />
      </div>
      <div className={` ${
          isOpen ? " open" : "unopen"
        }  col-auto col-lg-3 col-xl-2 px-0 `} style={{ width: `250px`,paddingLeft:"20px" }}/>
      <div
        className={` ${
          isOpen ? " open" : "unopen"
        }  col-auto col-lg-3 col-xl-2 px-0 `}
        style={{
          height: "100vh",
          boxSizing:"border-box",
          width: "260px",
          position: "fixed",
          backgroundColor: "black",
          borderRight: "1px solid",
          left: "15px",
          
          borderColor: "red",
          marginTop: "-50px",
        }}
      >
        <div className="d-flex flex-column align-items-center align-items-sm-start ps-3 pt-2 text-white " style={{marginTop:"65px",overflowY: "auto",height: "100vh", paddingBottom: "50px"}}>
					<ul
						className='w-100 nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start'
						id='menu'
					>
						{menus?.map((item, index) => {
							return (
								<li className='w-100' key={index}>
									<Link
										to={item?.href}
										className={`${
											location?.pathname === item?.href ? 'active' : ''
										} sidebar_btn px-0 align-middle w-100`}
									>
										<span className='ms-1 d-inline'>
											{item?.name}
										</span>
									</Link>
								</li>
							)
						})}
					</ul>
					<hr />
				</div>
			</div>
		</>
	)
}
