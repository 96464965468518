import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    theme: 'dark',
  };

  const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
      toggleTheme: (state, { payload }) => {
        state.theme = payload?.theme;
      }
    },
  });
  export const { toggleTheme } = themeSlice.actions;

  export default themeSlice.reducer;
  