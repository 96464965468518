import React, { useState } from 'react';
import { instance } from 'index';
import { toast } from 'react-toastify';
import { useLocation, useHistory} from 'react-router-dom';
import { useEffect } from 'react';
import Footer from 'components/common/Footer';
import Modal from 'react-bootstrap/Modal';
import { icons } from 'contants';
import Loader from 'components/common/Loader';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Application = () => {
  const history = useHistory()
  const [resume, setResume] = useState();
  const [career, setCareer] = useState();
  const [formatdata, setFormatdata] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const message = location?.state;
  const [benefits, setBenefits] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [type, setType] = useState('text');
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [formValues, setFormValues] = useState({
		name: '',
		email: '',
		mobile: '',
		experience: '',
		expected_salary: '',
		joining_date: '',
		degree: '',
		gender: '',
	})

	const handleClose = () => {
		setShow(false)
	}

	const handleNavigate = ()=>{
		setShow(false)
		history.push('/career')
	}

	const getApplication = () => {
		setFormValues({
			...formValues,
			name: '',
			email: '',
			mobile: '',
			experience: '',
			expected_salary: '',
			joining_date: '',
			degree: '',
			gender: '',
		})

		setResume('')
	}

	const postApplication = async (e) => {
		e.preventDefault()
		if(!formValues.name || !formValues.email || !formValues.experience || !formValues.expected_salary || !formValues.joining_date || !formValues.degree || !resume    ){
			return toast.error('Fill all mandatory fields')
		}
		try {
			setIsRequestSent(true)
			const data = new FormData()
			data.append('name', formValues?.name)
			data.append('email', formValues?.email)
			data.append('mobile', formValues?.mobile)
			data.append('experience', formValues?.experience)
			data.append('expected_salary', formValues?.expected_salary)
			data.append('joining_date', formValues?.joining_date)
			data.append('degree', formValues?.degree)
			data.append('gender', formValues?.gender)
			data.append('file', resume)
			data.append('is_active', true)
			data.append('career', message?.message)

			const result = await instance.post('job-application/', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			if (result?.status === 201) {
				// toast.success('Upload Application sucessfully!');
				setIsRequestSent(false)
				handleShow()
			}
		} catch (error) {
			setIsRequestSent(false)
			toast.error('Opps Error! Try Again :(')
			console.log({ error })
		}
		getApplication()
	}

	const getBenefits = async () => {
		try {
			const result = await instance.get('benefits/')

			if (result?.status === 200) {
				setIsLoading(false)
				setBenefits(result?.data)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const getCareer = async (id) => {
		try {
			const result = await instance.get(`career/${id}/`)

			if (result?.status === 200) {
				setIsLoading(false)
				setCareer(result?.data)
				setFormatdata(
					(result?.data?.description)
						.replace(/\r\n/g, '<br>')
						.replace(/• /g, '<li>')
						.replace(/''/g, '<strong>')
				)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const handleMobileChange = (value) => {
		 setFormValues({ ...formValues, mobile: value });
	  };
	
	//   // Function to format the mobile number as per your requirement
	//   const formatMobileNumber = (numericValue) => {
	// 	if (numericValue.startsWith('03')) {
	// 	  // If the input already starts with '03', keep it as is
	// 	  return numericValue.slice(0, 11); // Trim to ensure exactly 11 digits
	// 	} else if (numericValue.length <= 2) {
	// 	  // Add '03' as a prefix
	// 	  return '03' + numericValue;
	// 	} else if (numericValue.length <= 11) {
	// 	  // Add a hyphen after the second character
	// 	  return '03' + numericValue.slice(2, 4) + '-' + numericValue.slice(4);
	// 	} else {
	// 	  // After the hyphen, allow only 7 digits
	// 	  return '03' + numericValue.slice(2, 4) + '-' + numericValue.slice(4, 11);
	// 	}
	//   };

	useEffect(() => {
		getCareer(message?.message)
	}, [])

	useEffect(() => {
		getBenefits()
	}, [])

	return (
		<>
			<>
				{isLoading && <Loader />}
				<Modal show={show} onHide={handleClose} animation={false}>
					<Modal.Body
						style={{
							background: 'linear-gradient(90deg, #744f4f 1.05%, #c30505 100%)',
						}}
					>
						<h3 className='text-center text-white'>Submit Your Application</h3>
						<div className='text-center'>
							<img
								src={process.env.PUBLIC_URL + '/images/portfolio/completed.svg'}
								alt=''
								width='50%'
							/>
						</div>
						<div className='text-center mt-3'>
							<p className='h5 text-white'>
								We have noted your response successfuly!
							</p>
						</div>
						<p className='text-center mt-3 text-white'>
							Thank you for taking time we will get back to you shortly.
						</p>

						<div className='cmn-btn'>
							<button style={{ marginLeft: '7.5rem' }} onClick={handleNavigate}>
								Return to job Search
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</>

			<section className='application_section'>
				<div className='row '>
					<div className='col-lg-12 col-md-12'>
						<div className='application-card  col-12 bg-transparent'>
							<div className='d-flex flex-column col-lg-6 col-md-12 col-sm-12'>
								<p className='application-title text-start'> {career?.title}</p>
								<p
									dangerouslySetInnerHTML={{ __html: formatdata }}
									className='text-white w-100'
								/>
							</div>
							<div className='application-form col-lg-5 col-md-12 col-sm-12'>
								<form onSubmit={postApplication}>
									<h4 className='text-white text-center'>
										{' '}
										Submit Your Application
									</h4>
									<div className='row mt-4'>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={'text'}
												placeholder='Full Name*'
												value={formValues?.name}
												onChange={(e) =>
													setFormValues({ ...formValues, name: e.target.value })
												}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={'email'}
												placeholder='Email address*'
												value={formValues?.email}
												onChange={(e) =>
													setFormValues({
														...formValues,
														email: e.target.value,
													})
												}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
										{/* <input
											required
											className='form-control bd-input'
											type='text'
											placeholder='0311-1111111'
											value={formValues?.mobile}
											onChange={handleMobileChange}
										/> */}
										<PhoneInput
										placeholder="Enter phone number"
										className='form-control bd-input'
										value={formValues?.mobile || ''} // Provide a default value if undefined
										onChange={handleMobileChange}
										style={{ display: 'flex', borderRadius: '10px' }}
										
										/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={'text'}
												placeholder='Experience*'
												value={formValues?.experience}
												onChange={(e) =>
													setFormValues({
														...formValues,
														experience: e.target.value,
													})
												}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={'number'}
												placeholder='Expected Salary*'
												value={formValues?.expected_salary}
												onChange={(e) =>
													setFormValues({
														...formValues,
														expected_salary: e.target.value,
													})
												}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={type}
												placeholder='Date of joining*'
												onFocus={(event) => {
													event.target.type = 'date'
												}}
												id='date'
												value={formValues?.joining_date}
												onChange={(e) =>
													setFormValues({
														...formValues,
														joining_date: e.target.value,
													})
												}
												onClick={(e) => setType('date')}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<input
												className='form-control bd-input'
												type={'text'}
												placeholder='Recent Degree*'
												value={formValues?.degree}
												onChange={(e) =>
													setFormValues({
														...formValues,
														degree: e.target.value,
													})
												}
											/>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<select
												className='form-control bd-input '
												name='gender'
												id='gender'
												value={formValues?.gender}
												onChange={(e) =>
													setFormValues({
														...formValues,
														gender: e.target.value,
													})
												}
											>
												<option>select gender</option>
												<option>MALE</option>
												<option>FEMALE</option>
											</select>
										</div>
										<div className='col-md-12 col-sm-12 col-lg-12'>
											<div className='custom-file'>
												<input
													type='file'
													className='custom-file-input'
													id='customFile'
													onChange={(e) => setResume(e.target.files[0])}
													accept='.pdf'
												/>
												<label
													className=' d-flex '
													htmlFor='customFile'
													style={{ cursor: 'pointer' }}
												>
													{resume ? (
														resume.name
													) : (
														<>
															<p className='text-white'>Attach Cv *</p>
															<div style={{ marginBottom: '20px' }}>
																<img
																	className='clip my-1'
																	alt=''
																	src={icons.clip}
																	width='20px'
																/>
															</div>
														</>
													)}
												</label>
											</div>
										</div>
									</div>
									<div className='cmn-btn w-100'>
										<button
											type='submit'
											className='mt-2 w-100 border'
											disabled={isRequestSent}
										>
											{isRequestSent ? 'Submitting' : 'Submit'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className='benefits-container'>
					<div className='row'>
						<div className='col-12'>
							<div className='section-heading text-center'>
								<p className='main-title'>
									Perks and <span style={{ color: '#F50808' }}>Benefits</span>
								</p>
								<p className='description'>
									Bugdev cares about the financial and emotional well-being of
									its employees. While working <br />
									with Bugdev, you get the following perks and benefits:
								</p>
							</div>
						</div>

						<div className='col-md-12 col-lg-12 col-xl-12 mt-4 mb-5'>
							<div className='row '>
								{benefits?.results?.map((data, index) => {
									return (
										<div
											className='col-sm-6 col-md-6 col-lg-4 col-xl-4 '
											key={data?.id}
										>
											<div className='single-service'>
												<span className='count'>{index + 1}</span>
												<div className='service-icon'>
													<i>
														<img src={data?.image} alt='images' />
													</i>
												</div>
												<div className='service-content'>
													<h4>{data?.name}</h4>
													<p>{data?.description}</p>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	)
};

export default Application;
