/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { instance } from "index";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import Wireframe from "./animation/Wireframe.json";
import Developing from "./animation/Developing.json";
import Deployment from "./animation/deployment.json";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "components/common/Loader";
import Loader2 from "components/common/Loader2";

function ServiceDetailsWrapper() {
  const location = useLocation();
  const seriveID = location.state?.message;
  const [service, setService] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [formatdata, setFormatdata] = useState();
  const [techskils, setTechskills] = useState();
  const history = useHistory();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getServiceDetail = async (seriveID) => {
    try {
      const result = await instance.get(`services/${seriveID}/`);
      if (result?.status === 200) {
        setIsLoading(false);
        setService(result?.data);
        setFormatdata(
          (result?.data?.description)
            .replace(/\r\n/g, "<br>")
            .replace(/• /g, "<li>")
            .replace(/''/g, "<strong>")
        );
      }
    } catch (error) {
      console.log({ error });
    }
    scrollTop();
  };

  useEffect(() => {
    getServiceDetail(seriveID);
  }, [seriveID]);

  const contectForm = () => {
    history.push("/contact");
  };

  const getTechSkills = async () => {
    try {
      const result = await instance.get("tech-skills/");
      if (result?.status === 200) {
        setTechskills(result?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getTechSkills();
  }, []);

  return (
    <>
      <section className="service-details sec-mar padding-20">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-8 col-xl-8 or2 position-relative">
              {isLoading && <Loader2 />}
              <div className="signle-service-details">
                <h2 className="text-white">{service?.name}</h2>
                <p
                  dangerouslySetInnerHTML={{ __html: formatdata }}
                  className="text-white w-100"
                />
                <div className="cmn-btn justify-content-center">
                  <button className="mt-4" onClick={contectForm}>
                    Discuss your Project
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-xl-4 or1">
              <div className="sidebar-widget">
                <h4>Services</h4>
                <ul className="service-list">
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      Web Design <span>(15)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      Apps Development <span>(18)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      Software Development <span>(21)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      Motion Graphics <span>(25)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      UI/UX Design <span>(29)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollTop}
                      to={`${process.env.PUBLIC_URL}/service-details`}
                    >
                      <i>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/dash-circle-icon.svg"
                          }
                          alt="images"
                        />
                      </i>
                      Graphic Design <span>(31)</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="single-service-work-process">
            <h3>How We Work in our Services</h3>
            <div className="single-step">
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                      <span>01</span>
                    </div>
                    <h4>Wireframe &amp; Design</h4>
                    <p>
                      Wireframing is a way to design a website service at the
                      structural level. A wireframe is commenly used to layout
                      content and functionality on a page which takes into
                      account user needs and user journeys. Wireframes are used
                      early in the development process to establih the basic
                      structure of a page before visual design and content is
                      added.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <Lottie loop={true} animationData={Wireframe} />
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step">
              <div className="row">
                <div className="col-md-4 col-xl-4 d-flex justify-content-center">
                  <div className="step-img">
                    <Lottie
                      className="services lottie-img"
                      loop={true}
                      animationData={Developing}
                      style={{
                        marginTop: "-100px",
                        marginLeft: "-75px",
                        width: "500px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="step">
                    <div className="step-count">
                      <span>02</span>
                    </div>
                    <h4>Developing</h4>
                    <p>
                      Website development is a catch-all term for the work that
                      goes into building a website. This includes everything
                      from markup and coding to scripting, network
                      configuration, and CMS development. While web development
                      typically refers to web markup and coding, website
                      development includes all related development tasks, such
                      as client-side scripting, server-side scripting, server
                      and network security configuration, eCommerce development,
                      and content management system (CMS) development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-step" >
              <div className="row">
                <div className="col-md-8 col-xl-8 or2">
                  <div className="step">
                    <div className="step-count">
                      <span>03</span>
                    </div>
                    <h4>Checkup &amp; Launch</h4>
                    <p>
                      Walk through all of the steps required to build and launch
                      a new website or combine a websites in this comprehansive,
                      step-by-step SEO-focused guide. The decisions made in the
                      planning stage of a new site Launch either either
                      constrain the site and force it to underachieve or serve
                      as a foundation for seemingly limitless growth.This guide
                      to launching a new site will help ensure that it reaches
                      maximum potiential.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 or1">
                  <div className="step-img">
                    <Lottie loop={true} animationData={Deployment} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 flex-wrap">
                <h1 className="text-white">
                  Tools we use to <br />
                  organize our workflow
                </h1>
                <p className="text-white ">
                  we use alot of tools for communication and project management.
                  Slack is our internal communication tool, Jira for task
                  management, and Webex for video communication, but we are very
                  flexiable and can adapt to your prefrences.
                </p>

                <Slider {...settings}>
                  {techskils?.results?.map((item) => {
                    return (
                      <div className="col-2  mt-5" key={item?.id}>
                        <div className="tool-box">
                          <img src={item?.icon} alt="image" width="50px" />
                          <p className="text-center text-white">{item?.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceDetailsWrapper;
