/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import AnimatedCursor from "react-animated-cursor";
import { NavLink } from "react-router-dom";
import { toggleAuth } from "redux/auth/authSlice";

import { instance } from "index";
import Loader from "./Loader";

import Avatar from "../../assets/images/avatar.png";
import { toggleTheme } from "redux/theme/themeSlice";

/*---------Using reducer mange the active or inactive menu----------*/
const initialState = { activeMenu: "" };

function reducer(state, action) {
  switch (action.type) {
    case "homeOne":
      return { activeMenu: "homeOne" };
    case "service":
      return { activeMenu: "service" };
    case "projects":
      return { activeMenu: "projects" };
    case "blogs":
      return { activeMenu: "blogs" };
    default:
      throw new Error();
  }
}

function Header() {
  const history = useHistory();
  const handleDispacth = useDispatch();
  const { auth } = useSelector((state) => state);
  const { isLogin, userInfo } = useSelector((state) => state?.auth);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [service, setService] = useState();
  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [getProjects, setGetProjects] = useState();
  const [userDetail, setUserDetail] = useState({});
  const { theme } = useSelector((state) => state.theme);
  const handleToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    handleDispacth(toggleTheme({ theme: newTheme }));
  };

  const getAllProject = async () => {
    try {
      const result = await instance.get("projects/");
      if (result?.status === 200) {
        setGetProjects(result?.data);
        setIsProjectLoading(false);
      }
    } catch (error) {
      setIsProjectLoading(true);
      console.log({ error });
    }
  };

  const getService = async () => {
    try {
      const result = await instance.get("services/");

      if (result?.status === 200) {
        setIsServiceLoading(false);
        setService(result?.data);
      }
    } catch (error) {
      setIsServiceLoading(true);
      console.log({ error });
    }
  };

  const getUser = async (userId) => {
    try {
      const result = await instance.get(`profiles/`);

      if (result?.status === 200) {
        setIsServiceLoading(false);
        const profileData = result?.data.results.filter(
          (item) => item.user.id === userId
        );
        setUserDetail(profileData);
        // console.log("user data", profileData[0]?.profile_picture);
      }
    } catch (error) {
      setIsServiceLoading(true);
      console.log({ error });
    }
  };

  const projectDetail = (id) => {
    history.push({
      pathname: "/project-details",
      state: { message: id },
    });
    scrollTop();
  };

  const getServiceDetail = (id) => {
    history.push({
      pathname: "/service-details",
      state: { message: id },
    });
    scrollTop();
  };
  useEffect(() => {
    getAllProject();
    getService();
  }, []);

  useEffect(() => {
    if (auth?.userInfo?.user?.id) {
      getUser(auth?.userInfo?.user?.id);
    }
  }, [auth?.userInfo?.user?.id]);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".position_top");
    const scrollTop = window.scrollY;
    scrollTop >= 70
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };

  /*----for single sidebar event use one state-------*/
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

  const getDashboard = () => {
    // handleRole(userInfo?.user?.role?.name, history)

    if (auth?.userInfo?.user?.role?.name?.toLowerCase() === "developer") {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/developer-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
    if (auth?.userInfo?.user?.role?.name?.toLowerCase() === "project manager") {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/manager-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
    if (
      auth?.userInfo?.user?.role?.name?.toLowerCase() === "business developer"
    ) {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/business-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
    if (auth?.userInfo?.user?.role?.name?.toLowerCase() === "accounts") {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/account-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
    if (auth?.userInfo?.user?.role?.name?.toLowerCase() === "hr") {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/hr-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
    if (auth?.userInfo?.user?.role?.name?.toLowerCase() === "admin") {
      return (
        <NavLink
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/admin-dashboard`}
        >
          Dashboard
        </NavLink>
      );
    }
  };

  //  scroll top event

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSidebar(false);
  };

  return (
    <>
      <header className="position_top bg-dark header1" style={{ zIndex: 9999 }}>
        <div className="container-fluid">
          <div className="w-100 row align-items-center justify-content-between">
            <div className="col col-sm-3 col-md-3 col-lg-3 col-xl-2">
              <div className="logo">
                <Link onClick={scrollTop} to={"/"}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col col-sm-5 col-md-6 col-lg-9 col-xl-10 text-end">
              <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav"}>
                <div className="mobile-menu-logo">
                  <Link onClick={scrollTop} to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + "/images/logo.png"}
                      alt=""
                    />
                  </Link>
                </div>
                {auth?.isLogin ? (
                  <ul className="w-100 position-relative">
                    <li>{getDashboard()}</li>

                    <li
                      className="nav-item dropdown position-static"
                      onClick={() => dispatch({ type: "projects" })}
                    >
                      <NavLink
                        className="nav-link dropdown-toggle"
                        to={`${process.env.PUBLIC_URL}/project`}
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Project
                      </NavLink>
                      <div
                        className="dropdown-menu w-100  shadow border-outline-success start-0 grandDropdown grandSubmenu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul
                          className="sub-menu d-block position-relative grandSubmenu--list login-project"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {isProjectLoading ? (
                            <Loader />
                          ) : (
                            getProjects?.results?.map((data, index) => (
                              <li
                                className="loggedIn-porjects"
                                onClick={() => projectDetail(data?.id)}
                                key={data?.id}
                              >
                                <a
                                  className={data?.id}
                                  onClick={() => projectDetail(data?.id)}
                                >
                                  <img src={data?.icon} alt="" />
                                  {data?.name}
                                </a>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </li>

                    <li onClick={() => dispatch({ type: "blogs" })}>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/feed`}
                      >
                        Feed
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/event`}
                      >
                        Events
                      </NavLink>
                    </li>
                    {/* <li className="cmn-btn">
                      <button onClick={handleToggleTheme}>
                        {theme !== "dark" ? "🌙 Dark Mode" : "☀️ Light Mode"}
                      </button>
                    </li> */}

                    <div className="d-inline-flex justify-content-end user_setting_dropdown">
                      {auth?.isLogin ? (
                        <div>
                          <li className="has-child user_setting_dropdown">
                            <a
                              className="img-tag"
                              type="button"
                              id="dropdownMenuButton1"
                              // data-bs-toggle='dropdown'
                              aria-expanded="false"
                            >
                              <img
                                className="img-fluid user-img"
                                src={userDetail[0]?.profile_picture || Avatar}
                              />
                            </a>
                            <ul
                              className={
                                state.activeMenu === "user"
                                  ? "sub-menu d-block"
                                  : "sub-menu d-block"
                              }
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <Link to="/settings">Setting</Link>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDispacth(
                                      toggleAuth({
                                        isLogin: false,
                                        userInfo: null,
                                      })
                                    );
                                    history.push("/");
                                  }}
                                >
                                  logout
                                </button>
                              </li>
                            </ul>
                          </li>
                        </div>
                      ) : (
                        <div className="get-quate mx-lg-3 my-3 user_setting_dropdown">
                          <div className="d-flex gap-3">
                            <div className="cmn-btn">
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/login`}
                              >
                                Login
                              </Link>
                            </div>
                            <div className="cmn-btn">
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/contact`}
                              >
                                Get a quote
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                ) : (
                  <ul>
                    <li className="nav-item dropdown position-static">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/service`}
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        services
                      </NavLink>
                      <div
                        className="dropdown-menu  shadow border-outline-success start-0 grandDropdown grandSubmenu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul
                          className="sub-menu d-block position-relative grandSubmenu--list"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {isServiceLoading ? (
                            <Loader />
                          ) : (
                            service?.results?.map((item, index) => (
                              <li
                                onClick={() => getServiceDetail(item?.id)}
                                key={item?.id}
                              >
                                <Link to={`${process.env.PUBLIC_URL}/service`}>
                                  <img src={item?.icon} alt="" />
                                  {item?.name}
                                </Link>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </li>

                    <li className="nav-item dropdown position-static">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/project`}
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Projects
                      </NavLink>
                      <div
                        className="dropdown-menu  shadow border-outline-success start-0 grandDropdown grandSubmenu"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul
                          className="sub-menu d-block position-relative grandSubmenu--list"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {isProjectLoading ? (
                            <Loader />
                          ) : (
                            getProjects?.results?.map((data, index) => (
                              <li
                                key={data?.id}
                                onClick={() => projectDetail(data?.id)}
                              >
                                <a
                                  className={data?.id}
                                  onClick={() => projectDetail(data?.id)}
                                >
                                  <img src={data?.icon} alt="" />
                                  {data?.name}
                                </a>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    </li>
                    <li className="nav-item dropdown position-relative">
                      <div
                        className="nav-link dropdown-toggle text-white menu-dropdown"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Company
                      </div>
                      <div
                        className="dropdown-menu  shadow border-outline-success start-0 grandDropdown grandSubmenu smallSubmenu position-absolute"
                        aria-labelledby="navbarDropdown"
                      >
                        <ul
                          className="sub-menu d-block position-relative grandSubmenu--list"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <NavLink
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/feed`}
                            >
                              Feed
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/event`}
                            >
                              Events
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/contact`}
                            >
                              Contact us
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li>
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/career`}
                      >
                        Career
                      </NavLink>
                    </li>
					{/* <li className="cmn-btn">
                      <button onClick={handleToggleTheme}>
                        {theme !== "dark" ? "🌙 Dark Mode" : "☀️ Light Mode"}
                      </button>
                    </li> */}
                    <div className="d-inline-flex justify-content-end user_setting_dropdown">
                      {auth?.isLogin ? (
                        <div>
                          <li className="has-child user_setting_dropdown">
                            <a
                              className="img-tag"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                className="img-fluid user-img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/user-img.svg"
                                }
                              />
                            </a>
                            <ul
                              className={
                                state.activeMenu === "user"
                                  ? "sub-menu d-block"
                                  : "sub-menu d-block"
                              }
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <Link to="/settings">Setting</Link>
                              </li>
                              <li>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDispacth(
                                      toggleAuth({
                                        isLogin: false,
                                        userInfo: null,
                                      })
                                    );
                                    history.push("/");
                                  }}
                                >
                                  logout
                                </button>
                              </li>
                            </ul>
                          </li>
                        </div>
                      ) : (
                        <div className="get-quate mx-0 mx-xl-3 my-3 user_setting_dropdown">
                          <div className="flex-column flex-xl-row common-btn">
                            <div className="login-btn">
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/login`}
                              >
                                Login
                              </Link>
                            </div>
                            <div className="cmn-btn">
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/contact`}
                              >
                                Get a quote
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                )}
              </nav>
              <div className="mobile-menu">
                <div
                  onClick={showSidebar}
                  className={sidebar === 1 ? "cross-btn h-active" : "cross-btn"}
                >
                  <span className="cross-top" />
                  <span className="cross-middle" />
                  <span className="cross-bottom" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
