/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import { Link, useRouteMatch } from 'react-router-dom'
import { instance } from 'index'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProjectChart from './ProjectChart'
import { TransactionChart } from '../../accountDashboard/pages/TransactionChart'
import RevenueChart from './RevenueCharts'

const AccountsDashbord = () => {
	const { path } = useRouteMatch();
	const {access} = useSelector((state) => state.auth.userInfo)
	const [empSummary, setEmpSummary] = useState(0)
	const [projectsLength, setPorjectsLength] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [userPayment, setUserPayment] = useState([])
	const [salary, setSalary] = useState()

	const employeeData = async () => {
		try {
			const result = await instance.get('profiles/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setEmpSummary(result?.data?.results?.length)
			}
		} catch (error) {
			console.log({ error })
		}
	}


	const projectsData = async () => {
		try {
			const result = await instance.get('projects/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setPorjectsLength(result?.data?.results?.length)
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const getAllPayments = async () => {
		setIsLoading(true)
		try {
			const result = await instance.get('employee-payment/',{
				headers: {
					'Authorization': `Bearer ${access}`,
				  },
			})
			if (result?.status === 200) {
				setIsLoading(false)
				const paymentDataResponse = result?.data.results
				const monthlyPayments = {}
				const currentYear = new Date().getFullYear()

				paymentDataResponse.forEach((payment) => {
					const createdDate = new Date(payment.created_at)
					const paymentYear = createdDate.getFullYear()
					const currentMonth = new Date().toLocaleString('default', { month: 'long' })

					if (paymentYear === currentYear) {
						const month = createdDate.toLocaleString('en-US', { month: 'long' })
						const salary = parseFloat(payment.amount.replace(/[^0-9.-]+/g, ''))

						if (monthlyPayments[month]) {
							monthlyPayments[month] += salary
						} else {
							monthlyPayments[month] = salary
						}
					}	
				})

				const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					if (monthName  === currentMonth){
						{
							console.log(" this month salary", monthlyPayments[monthName])
							setSalary(monthlyPayments[monthName])
						}
					}
					return {
						month: monthName,
						totalSalary: monthlyPayments[monthName] || 0,
						
					}
				})

				setUserPayment(allMonths)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	console.log("user payment :", userPayment)
	useEffect(() => {
		getAllPayments()
	}, [])

	const currentMonth = new Date().toLocaleString('default', { month: 'long' })
	useEffect(() => {
		projectsData()
		employeeData()
	}, [])

	return (
		<>
			<section className='Admindashboard__task__wrapper '>
				<div className='dashboard-container'>
					<div className='row mb-5'>
						<div className='col-12 d-flex align-items-center justify-content-between'>
							<p className='title'>Accounts Dashboard</p>
						</div>
					</div>
                    <div className='row mb-5'>
						<div className='col-md-4'>
							{/* <Link to={`${path}/employee`}> */}
								<div className='dashboardCard'>
									<div className='d-flex justify-content-between'>
										<p className='' style={{fontSize:'20px'}}>
										Employees
										</p>
                                        <img src={process.env.PUBLIC_URL +
										 '/images/icons/employe.png'}
										  alt='images'
                                          style={{width:'40px'}}
										  />
									</div>
									<p>{empSummary}</p>
								</div>
							{/* </Link> */}
						</div>
						<div className='col-md-4'>
							{/* <Link to={`${path}/monthly-detail`}> */}
                            <div className='dashboardCard'>
									<div className='d-flex justify-content-between'>
										<p className='' style={{fontSize:'20px'}}>
										Projects
										</p>
                                        <img src={process.env.PUBLIC_URL +
										 '/images/icons/project.png'}
										  alt='images'
                                          style={{width:'40px'}}
										  />
									</div>
									<p>{projectsLength}</p>
								</div>
							{/* </Link> */}
						</div>
                        <div className='col-md-4'>
							{/* <Link to={`${path}/monthly-detail`}> */}
                            <div className='dashboardCard'>
									<div className='d-flex justify-content-between'>
										<p className='' style={{fontSize:'20px'}}>
										Current month salaries
										</p>
                                        <img src={process.env.PUBLIC_URL +
										 '/images/icons/dollar.png'}
										  alt='images'
                                          style={{width:'40px'}}
										  />
									</div>
									<p>{salary} PKR</p>
								</div>
							{/* </Link> */}
						</div>
					</div>
                    <div className=' mb-5'>
						<div className='col-md-12 mb-5'>
							<Link to={`${path}/project-table`}>
								<div className='dashboardCard'>
									<div className=''>
										<p className=''>
										Projects
										</p>
									</div>
									<ProjectChart/>
								</div>
							</Link>
						</div>
						<div className='col-md-12'>
							<Link to={`${path}/salary-table`}>
								<div className='dashboardCard'>
									<div className=''style={{ position: 'relative', width: '100%' }}>
										<p className=''>
										Salaries
										</p>
										<TransactionChart/>
									</div>
									
								</div>
							</Link>
						</div>
					</div>
					<div className='row mb-5'>
						<div className='col-md-12'>
							<Link to={`${path}/revenue-table`}>
								<div className='dashboardCard'>
									<div className=''>
										<p className=''>
										Revenue
										</p>
									</div>
									<RevenueChart/>
								</div>
							</Link>
						</div>
					</div>
					
				</div>
			</section>
		</>
	)
}

export default AccountsDashbord