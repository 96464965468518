import React,{useEffect, useState} from 'react';
import { Line } from 'react-chartjs-2';
import { instance } from 'index';
import { useSelector } from 'react-redux';

const JobAnalysisChart = () => {
  const { access } = useSelector((state) => state.auth.userInfo);
  const [jobanalysis, setJobAnalysis] = useState([]);
  const [months, setMonths] = useState([])

  const getJobAnalysis = async () => {
    try {
      const result = await instance.get('job-application/', {
        headers: {
          Authorization: `Bearer ${access}`
        }
      });
  
      if (result?.status === 200) {
        const jobdataResponse = result?.data.results;
        const currentYear = new Date().getFullYear();
        const monthlyPayments={}
  
        // Create an object to store counts for each status per month
        const monthlyApplications = {
          HIRE: Array(12).fill(0), // Initialize an array with zeros for all months
          FIRED: Array(12).fill(0),
          RESIGN: Array(12).fill(0),
        };
  
        jobdataResponse.forEach((application) => {
          const createdDate = new Date(application.created_at);
          const applicationYear = createdDate.getFullYear();
  
          if (applicationYear === currentYear) {
            const month = createdDate.getMonth(); // Get the month index (0-11)
            const status = application.status;
  
            if (monthlyApplications.hasOwnProperty(status)) {
              // Increment the count for the corresponding status and month
              monthlyApplications[status][month]++;
            }
          }
        });
  
        // Now, monthlyApplications contains the counts for each status per month
        console.log("monthly applications",monthlyApplications);
        setJobAnalysis(monthlyApplications)
        const allMonths = Array.from({ length: 12 }, (_, i) => {
					const monthName = new Date(null, i).toLocaleString('en-US', {
						month: 'long',
					})
					return {
						month: monthName,
						totalPayment: monthlyPayments[monthName] || 0,
						
					}
				})

        setMonths(allMonths)
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(()=>{
    getJobAnalysis();
  },[])

  const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
  
  const data = {
    labels: months.slice(0, currentMonth)?.map((label) => label?.month),
    datasets: [
      {
        label: 'Hired',
        data: jobanalysis?.HIRE,
        borderColor: 'rgba(75, 192, 192, 1)',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Fired',
        data: jobanalysis?.FIRED,
        borderColor: 'rgba(192, 75, 75, 1)',
        yAxisID: 'y-axis-2',
      },
      {
        label: 'Resign',
        data: jobanalysis?.RESIGN,
        borderColor: 'green',
        yAxisID: 'y-axis-2',
      },
    ],
  };

  const options = {
    responsive: true, // Enable responsiveness
    maintainAspectRatio: false, // Allow the chart to take the full width/height of the container
    scales: {
      x: {
        beginAtZero: true,
      },
      y: [
        {
          id: 'y-axis-1',
          type: 'linear',
          position: 'left',
        },
        {
          id: 'y-axis-2',
          type: 'linear',
          position: 'right',
        },
      ],
    },
  };

  return (
    <div className="multi-axis-line-chart" style={{ position: 'relative', height: '400px', width: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default JobAnalysisChart;