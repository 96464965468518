import React, { useMemo, useState, useEffect } from "react";
import ProjectTable from "./components/ProjectTable";
import moment from "moment";
import { instance } from "index";
import Loader from "components/common/Loader";
import Loader3 from "components/common/Loader3";

export default function PendingProject() {
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "name",
        filterable: false,
        width: 300,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Live URL",
        accessor: "live_url",
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <a
                href={cellProps?.cell?.row?.original?.live_url}
                className="text-white"
              >
                Open in Browser
              </a>
            </div>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: "deadline",
        filterable: false,
        width: 300,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ""
            ? moment.utc(cell?.value?.toString()).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        width: 400,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cell) => {
          return (
            <div
              className={`dotDiv d-flex justify-content-center align-items-center w-100 text-white`}
            >
              <span className={cell?.value?.toLowerCase()} />
              {cell?.value}
            </div>
          );
        },
      },
    ],
    []
  );

  const getDeliveredProjects = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get(`projects/`);
      if (result?.status === 200) {
        setIsLoading(false);
        const deliveredProjects = result?.data?.results.filter(
          (project) => project.status === "completed"
        );

        setProject(deliveredProjects);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    getDeliveredProjects();
  }, []);

  return (
    <>
      <div className="myTime d-flex justify-content-between mb-2 align-items-center mb-3">
        <h6 className="text-white PaytableHeading">Delivered Projects</h6>
      </div>
      {isLoading ? (
        <Loader3 />
      ) : (
        <div className="row">
          <div className="col-12">
            {project && (
              <ProjectTable
                tableStyles={{ tableLayout: "fixed" }}
                columns={columns}
                initialState={{
                  columnVisibility: { conversion: false },
                }}
                data={project}
                total={project?.length}
                loading={isLoading}
                customPageSize={1}
                divclassName="table-responsive mb-1"
                tableclassName="align-middle table-nowrap"
                theadclassName="table-light text-muted"
                getRowId={(row, relativeIndex, parent) =>
                  console.log("ROw id.....", row?.id)
                }
                fetchData={() => console.log("fetchData")}
                pageCount={3}
                currentPage={1}
                isNextPage={2}
                isPrevPage={null}
                nextHandler={() => console.log("Next")}
                prevHandler={() => console.log("Prev")}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
