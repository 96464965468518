import Table from "components/common/table/Table";
import { timeTable } from "contants/data";
import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import { instance } from "index";
import { useSelector } from "react-redux";
import Loader3 from "components/common/Loader3";

export default function Attendance() {
  const { auth } = useSelector((state) => state);
  const { access } = useSelector((state) => state.auth.userInfo);
  const [tableList, setTableList] = useState(timeTable);
  const [userAttendance, setUserAttendance] = useState(null);
  const [isLoading, setIsLoading] = useState();

  const getUserAttendance = async (userId) => {
    setIsLoading(true);
    try {
      const result = await instance.get(`employee-attendance/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        const UserAttendanceData = result?.data.results.filter(
          (item) => item.user.id !== userId
        );
        setUserAttendance(UserAttendanceData);
      }
    } catch (error) {
      setIsLoading(true);
      console.log({ error });
    }
  };

  useEffect(() => {
    if (auth?.userInfo?.user?.id) {
      getUserAttendance(auth?.userInfo?.user?.id);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        width: 300,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className="dotDiv d-flex align-items-center justify-content-center w-100"
            >
              {`${cellProps.row.original.user.first_name} ${cellProps.row.original.user.last_name}`}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        filterable: false,
        width: 250,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ""
            ? moment.utc(cell?.value?.toString()).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Weekly Hours",
        accessor: "week",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className="dotDiv d-flex align-items-center justify-content-center w-100"
            >
              <span className="span-badge"></span>
              {cellProps.row.original.week}
            </div>
          );
        },
      },
      {
        Header: "Working hours",
        accessor: "number_of_hours",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className="dotDiv d-flex align-items-center justify-content-center w-100"
            >
              <span className="span-badge"></span>
              {cellProps.row.original.number_of_hours}
            </div>
          );
        },
      },
      {
        Header: "OverTime",
        accessor: "overtime_hours",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div
              key={cellProps.row.original?.id}
              className="dotDiv d-flex align-items-center justify-content-center w-100"
            >
              {/* <span className='span-badge'></span> */}
              {cellProps.row.original.overtime_hours}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="myTime d-flex justify-content-between mb-2 align-items-center ">
        <h6 className="text-white tableHeading">Attendance Dashbord </h6>
      </div>
      {isLoading ? (
        <Loader3 />
      ) : (
        <div className="row">
          <div className="col-12">
            <div
              className="card  table-card overflow-auto"
              style={{ backgroundColor: "transparent" }}
            >
              {userAttendance && (
                <Table
                  tableStyles={{ tableLayout: "fixed" }}
                  columns={columns}
                  initialState={{
                    columnVisibility: { conversion: false },
                  }}
                  data={userAttendance}
                  total={userAttendance?.length}
                  customPageSize={1}
                  divclassName="table-responsive mb-1"
                  tableclassName="align-middle table-nowrap"
                  theadclassName="table-light text-muted"
                  getRowId={(row, relativeIndex, parent) => row?.id}
                  fetchData={() => console.log("fetchData")}
                  pageCount={3}
                  currentPage={1}
                  isNextPage={2}
                  isPrevPage={null}
                  nextHandler={() => console.log("Next")}
                  prevHandler={() => console.log("Prev")}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
