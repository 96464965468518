import React, { useState, useEffect } from "react";
import { icons } from "../../../../../contants";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { instance } from "index";
import Loader2 from "components/common/Loader2";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "components/common/Loader";
import Loader3 from "components/common/Loader3";

export default function Benefits() {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setIsCheck(true);
    setFormValues({
      name: "",
      description: "",
    });
  };
  const [benefits, setBenefits] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState({ name: "", description: "" });
  const [request, setRequest] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isCheck, setIsCheck] = useState(true);
  const [getId, setGetId] = useState("");
  const { access } = useSelector((state) => state.auth.userInfo);

  const getBenefits = async () => {
    try {
      const result = await instance.get("benefits/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });

      if (result?.status === 200) {
        setIsLoading(false);
        setBenefits(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const sendRequest = async () => {
    if (!formValues?.name || !formValues?.description)
      return toast.error("All fields are required!");
    try {
      var form = {
        name: formValues?.name,
        description: formValues?.description,
        image: selectedImage,
        is_active: "true",
      };

      const result = await instance.post("benefits/", form, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        toast.success("Request sent sucessfully!");
        setModal(false);
        setRequest(result?.data);
      }
      getBenefits();
    } catch (error) {
      console.log({ error });
    }
  };

  const getDelete = async (eventId) => {
    console.log("eventId", eventId);
    try {
      const result = await instance.delete(`benefits/${eventId}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 204) {
        getBenefits();
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };
  const getEdit = async () => {
    try {
      const result = await instance.patch(`benefits/${getId}/`, {
        name: formValues?.name,
        description: formValues?.description,
        Authorization: `Bearer ${access}`,
      });
      if (result?.status === 200) {
        toast.success("Benefit updated sucessfully!");
        setModal(false);
        getBenefits();
        setFormValues({
          ...formValues,
          description: "",
          title: "",
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const editToggle = (idData) => {
    setFormValues({
      ...formValues,
      description: idData?.description,
      name: idData?.name,
      date: idData?.date,
    });
    console.log(idData);
    setGetId(idData?.id);
    setModal(!modal);
    setIsCheck(false);
  };

  useEffect(() => {
    getBenefits();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="myTime d-flex justify-content-between mb-2 align-items-center mb-4">
        <h6 className="text-white tableHeading"></h6>
        <div className="cmn-btn">
          <button className="mb-0 mt-1 " onClick={toggle}>
            Add Benefit
          </button>
        </div>
      </div>
      <div sstyle={{ display: "block" }}>
        {isCheck ? (
          <Modal isOpen={modal} toggle={toggle} className="modal-popups">
            <ModalBody>
              <h3 className="text-center text-white">Add Benefit</h3>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      style={{ borderRadius: "20px", height: "3rem" }}
                      value={formValues?.name}
                      onChange={(e) =>
                        setFormValues({ ...formValues, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      id="message-text"
                      style={{ height: "100px", borderRadius: "20px" }}
                      value={formValues?.description}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="file" className="col-form-label">
                      Upload Image:
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      style={{ borderRadius: "20px" }}
                      onChange={handleImageChange}
                    />
                  </div>
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="cmn-btn">
                <button onClick={toggle}>Close</button>
              </div>
              <div className="cmn-btn">
                <button onClick={sendRequest}>Save</button>
              </div>
            </ModalFooter>
          </Modal>
        ) : (
          <Modal isOpen={modal} toggle={toggle} className="modal-popups">
            <ModalBody>
              <h3 className="text-center text-white">Edit Benefit</h3>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Title:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      style={{ borderRadius: "20px", height: "3rem" }}
                      value={formValues?.name}
                      onChange={(e) =>
                        setFormValues({ ...formValues, name: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      id="message-text"
                      style={{ height: "100px", borderRadius: "20px" }}
                      value={formValues?.description}
                      onChange={(e) =>
                        setFormValues({
                          ...formValues,
                          description: e.target.value,
                        })
                      }
                    ></textarea>
                  </div>
                  {/* <div className='form-group'>
										<label htmlFor='file' className='col-form-label'>
											Upload Image:
										</label>
										<input
											type='file'
											className='form-control'
											id='file'
											style={{ borderRadius: '20px' }}
											onChange={handleImageChange}
										/>
										giwd wdrl hd oasdsy wd u ud
										yd wd uqwd iuywi uu udui uwdu
										hdi uio 
									</div> */}
                </form>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="cmn-btn">
                <button onClick={toggle}>Close</button>
              </div>
              <div className="cmn-btn">
                <button onClick={getEdit}>Save</button>
              </div>
            </ModalFooter>
          </Modal>
        )}
      </div>
      <div className="benefitText">
        <h1 className="text-white  tableHeading p-0">Perks and Benefits</h1>
        <p className="text-white ">
          Bugdev cares about the financial and emotional well-being of its
          employees. While working with Bugdev, you get the following perks and
          benefits:
        </p>
      </div>
      {isLoading ? (
        <Loader3 />
      ) : (
        <div className="col-12 ">
          <div className="row benefit-row justify-content-center gap-4 mt-3">
            <div className="col-md-12 col-lg-12 col-xl-12 mt-4 mb-5">
              <div className="row ">
                {benefits?.results?.map((data, index) => {
                  return (
                    <div
                      className="col-sm-6 col-md-6 col-lg-6 col-xl-6 "
                      key={data?.id}
                    >
                      <div className="single-service position-relative">
                        <div className="position-absolute d-flex justify-content-center align-items-center benefits-btn">
                          <button
                            className="border-0 event_action-btn"
                            style={{ width: "40px", height: "40px" }}
                            id={data?.id}
                            onClick={() => getDelete(data?.id)}
                          >
                            <i
                              className="fa fa-trash"
                              style={{
                                color: "red",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                            ></i>
                          </button>
                          <button
                            className="border-0 event_action-btn"
                            style={{ width: "40px", height: "40px" }}
                            onClick={() => editToggle(data)}
                          >
                            <i
                              className="fa fa-pen"
                              style={{
                                color: "#000",
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                            ></i>
                          </button>
                        </div>
                        <span className="count">{index + 1}</span>
                        <div className="service-icon">
                          <i>
                            <img src={data?.image} alt="images" />
                          </i>
                        </div>
                        <div className="service-content">
                          <h4>{data?.name}</h4>
                          <p>{data?.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
