import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { AiFillProject } from "react-icons/ai";
import { MdComputer } from "react-icons/md";
import { BsNewspaper, BsThreeDotsVertical } from "react-icons/bs";
import { instance } from "index";
import ProjectTable from "./components/ProjectTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Loader3 from "components/common/Loader3";

const Dashbord = () => {
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "name",
        filterable: false,
        width: 300,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Live URL",
        accessor: "live_url",
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <a
                href={cellProps?.cell?.row?.original?.live_url}
                className="text-white"
              >
                Open in Browser
              </a>
            </div>
          );
        },
      },
      {
        Header: "Due Date",
        accessor: "deadline",
        filterable: false,
        width: 300,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ""
            ? moment.utc(cell?.value?.toString()).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        width: 400,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cell) => {
          return (
            <div
              className={`dotDiv d-flex justify-content-center align-items-center w-100 text-white`}
            >
              <span className={cell?.value?.toLowerCase()} />
              {cell?.value}
            </div>
          );
        },
      },
    ],
    []
  );

  const getAllProjects = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get(`projects/`);
      if (result?.status === 200) {
        setIsLoading(false);
        setProject(result?.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const completedCount = project?.results.filter(
    (project) => project.status === "completed"
  ).length;

  const inProgressCount = project?.results.filter(
    (project) => project.status === "in-progress"
  ).length;

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <>
      <section className="dashboard__task__wrapper">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <p className="title">Dashboard</p>
            </div>
          </div>
          {isLoading ? (
            <Loader3 />
          ) : (
            <>
              <div className="row mb-5 me-2">
                <div className="col-md-4">
                  <div className="dashboardCard">
                    <div className="title">
                      <p className="">
                        <AiFillProject />
                      </p>
                      <button>
                        <BsThreeDotsVertical />
                      </button>
                    </div>
                    <h6>New Projects</h6>
                    <p>05</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <Link to={`/business-dashboard/pending-projects`}>
                    <div className="dashboardCard">
                      <div className="title">
                        <p className="">
                          <BsNewspaper />
                        </p>
                        <button>
                          <BsThreeDotsVertical />
                        </button>
                      </div>
                      <h6>Pending Projects</h6>
                      <p>{inProgressCount}</p>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4">
                  <Link to={`/business-dashboard/delivered-projects`}>
                    <div className="dashboardCard">
                      <div className="title">
                        <p className="">
                          <MdComputer />
                        </p>
                        <button>
                          <BsThreeDotsVertical />
                        </button>
                      </div>
                      <h6>Delivered Projects</h6>
                      <p>{completedCount}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="card table-card overflow-auto"
                    style={{ background: "transparent" }}
                  >
                    <div className="sidebar-search d-flex justify-content-between mb-3">
                      <h3 className="title">Project Detail</h3>
                    </div>
                    {project && (
                      <ProjectTable
                        tableStyles={{ tableLayout: "fixed" }}
                        columns={columns}
                        initialState={{
                          columnVisibility: { conversion: false },
                        }}
                        data={project?.results}
                        total={project?.length}
                        loading={isLoading}
                        customPageSize={1}
                        divclassName="table-responsive mb-1"
                        tableclassName="align-middle table-nowrap"
                        theadclassName="table-light text-muted"
                        getRowId={(row, relativeIndex, parent) =>
                          console.log("ROw id.....", row?.id)
                        }
                        fetchData={() => console.log("fetchData")}
                        pageCount={3}
                        currentPage={1}
                        isNextPage={2}
                        isPrevPage={null}
                        nextHandler={() => console.log("Next")}
                        prevHandler={() => console.log("Prev")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Dashbord;
