/* eslint-disable no-lone-blocks */
/* eslint-disable no-undef */
import { Link, useRouteMatch } from 'react-router-dom'
import ProjectChart from '../Accounts/ProjectChart'
import ResourceAnalysis from './ResourceAnalysis'
import ApplicationChart from '../HR/ApplicationChart'
import MapChart from './MapChart'

const BDdashboard = () => {
	const { path } = useRouteMatch()
	// const {access} = useSelector((state) => state.auth.userInfo)
	// const [empSummary, setEmpSummary] = useState(0)
	// const [projectsLength, setPorjectsLength] = useState(0)
	// const [isLoading, setIsLoading] = useState(false)
	// const [userPayment, setUserPayment] = useState([])
	// const [salary, setSalary] = useState()

	return (
		<>
			<section className='Admindashboard__task__wrapper'>
				<div className='dashboard-container'>
					<div className='row mb-5'>
						<div className='col-12 d-flex align-items-center justify-content-between'>
							<p className='title'>Business Developer Dashboard</p>
						</div>
					</div>
                    <div className='row mb-5'>
						<div className='col-md-6'>
							<Link to={`${path}/resource-table`}>
								<div className='dashboardCard'>
									<div className='' >
										<p className=''>
										Project Resource 
										</p>
									</div>
									<ResourceAnalysis/>
								</div>
							</Link>
						</div>
						<div className='col-md-6'>
							{/* <Link to={`${path}/monthly-detail`}> */}
								<div className='dashboardCard'>
									<div className='' style={{ position: 'relative', width: '100%', height: '400px' }}>
										<p className=''>
										Clients Satisfaction
										</p>
										<MapChart/>
									</div>
									
								</div>
							{/* </Link> */}
						</div>
					</div>
					<div className='row mb-5'>
						<div className='col-md-12'>
							<Link to={`${path}/project-table`}>
								<div className='dashboardCard'>
									<div className=''>
										<p className=''>
										Project Analysis 
										</p>
									</div>
									<ProjectChart/>
								</div>
							</Link>
						</div>
					</div>
					
				</div>
			</section>
		</>
	)
}

export default BDdashboard