/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react'
import { instance } from 'index'
import moment from 'moment'
import ImageViewer from 'react-simple-image-viewer'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Loader2 from 'components/common/Loader2'
import TimeDifference from 'components/common/TimeDifference'
import Avatar from '../../../assets/images/avatar.png'

function BlogPage() {
	const [selectedPostIndex, setSelectedPostIndex] = useState(null)
	const [currentTab, setCurrentTab] = useState('')
	const [post, setPost] = useState([])
	const [postImage, setPostImage] = useState([])
	const [currentImage, setCurrentImage] = useState(0)
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const [previewImageIndex, setPreviewImageIndex] = useState(0)
	const auth = useSelector((state) => state.auth);
	const [isPost, setIsPost] = useState(true)
	const [previewSources, setPreviewSources] = useState([])
	const [searchQuery, setSearchQuery] = useState('')
	const addPostImages = []
	const postSlideImages = []
	const postSlideVideos = []
	const addVideo = []
	const [isLoading, setIsLoading] = useState(true)
	const [video, setVideo] = useState()
	const [videoPreview, setVideoPreview] = useState(null)
	const [canUploadImage, setCanUploadImage] = useState(true)
	const [canUploadVideo, setCanUploadVideo] = useState(true)
	const [commentValue, setCommentValue] = useState()
	const [isShowComment, setIsShowComment] = useState(null)
	const [peopleData, setPeopleData] = useState([])
	const accessToken = auth?.userInfo?.access
	const [postText, setPostText] = useState({
		title: '',
		type: '',
	})

	const handleInputChange = (event) => {
		setPostText((prev) => {
			return { ...prev, [event.target.name]: event.target.value }
		})
	}

	const handleVideoChange = (event) => {
		const file = event.target.files[0]
		setVideo(file)

		if (file) {
			const videoURL = URL.createObjectURL(file)
			setVideoPreview(videoURL)
		}

		setCanUploadImage(false)
	}

	const handleImageChange = (event) => {
		const imgFiles = event.target.files
		const previewSources = []
		for (let i = 0; i < imgFiles.length; i++) {
			previewSources.push(URL.createObjectURL(imgFiles[i]))
			setPostImage((prev) => {
				return [...prev, imgFiles[i]]
			})
		}
		setPreviewSources(previewSources)
		setCanUploadVideo(false)
	}

	const sendImages = async () => {
		let imgCount
		setIsPost(false)

		if (postImage.length) {
			setIsPost(false)

			try {
				for (imgCount = 0; imgCount < postImage.length; imgCount++) {
					const form = {
						image: postImage[imgCount],
						is_active: true,
						user: auth?.userInfo?.user?.id,
					}
					const result = await instance.post('image/', form, {
						headers: {
							'content-type': 'multipart/form-data',
						},
					})
					if (result?.status === 201) {
						addPostImages.push(result?.data?.id)
						if (imgCount == postImage.length - 1) {
							publishFeed()
						}
					}
				}
			} catch (error) {
				toast.error('Something Went Wrong. Please try again to post')
				setIsPost(true)
				console.log({ error })
			}
		} else if (video) {
			try {
				setIsPost(false)

				var form = {
					video: video,
					is_active: true,
					user: auth?.userInfo?.user?.id,
				}
				const result = await instance.post('video/', form, {
					headers: {
						'content-type': 'multipart/form-data',
					},
				})

				if (result?.status === 201) {
					const newVideoId = result?.data?.id
					addVideo.push(newVideoId)
					console.log('Video Posted Successfully------>', newVideoId)
					publishFeed()
				}
			} catch (error) {
				toast.error('Something Went Wrong. Please try again to post')
				console.log({ error })
			}
		} else {
			publishFeed()
		}
	}

	const publishFeed = async () => {
		try {
			var form = {
				title: postText?.title,
				type: 'tech',
				images: addPostImages,
				videos: addVideo,
				user: auth?.userInfo?.user?.id,
			}
			const result = await instance.post('posts/', form, {
				headers: {
					'content-type': 'application/json',
				},
			})
			if (result?.status === 201) {
				toast.success('Post added sucessfully!')
				setIsPost(true)
				setPostText({ title: '', type: '' })
				setPreviewSources([])
				addVideo.length = 0
				setVideoPreview(null)
				addPostImages.length = 0
				postImage.length = 0
				setCanUploadVideo(true)
				setCanUploadImage(true)
				viewPosts(searchQuery)
				setCurrentTab('Latest')
			}
		} catch (error) {
			toast.error('Something Went Wrong. Please try again to post')
			setIsPost(true)
			console.log({ error })
		}
	}

	const delFeedPost = async (e) => {
		try {
			const config = {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
			const results = await instance.delete(`posts/${e.target.id}/`, config)
			if (results?.status >= 200 && results?.status < 300) {
				toast.success('Post Deleted sucessfully!')
				viewPosts(searchQuery)
				setSelectedPostIndex(null)
			}
		} catch (error) {
			toast.error('Something Went Wrong. Please try again to post')
			console.log('Error deleting Post', error)
		}
	}

	const handlePost = () => {
		if (!postText?.title) return toast.error('Feed Description is Required')

		setIsPost(false)
		sendImages()
	}

	post[previewImageIndex]?.images.map((items) =>
		postSlideImages.push(items.image)
	)

	const openImageViewer = useCallback((e) => {
		const mainFeedbox = e.target.closest('.feed-card')
		const feedBoxIndex = mainFeedbox.getAttribute('element-index')
		setPreviewImageIndex(feedBoxIndex)

		setCurrentImage(e.target.getAttribute('element-index'))
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}

	const viewPosts = async (searchQuery) => {
		try {
			const result = await instance.get('posts/')
			if (result?.status === 200) {
				let filteredResults = result?.data?.results
				if (searchQuery) {
					filteredResults = filteredResults.filter(
						(post) =>
							post.user.first_name
								.toLowerCase()
								.includes(searchQuery.toLowerCase()) ||
							post.user.last_name
								.toLowerCase()
								.includes(searchQuery.toLowerCase()) ||
							post.title.toLowerCase().includes(searchQuery.toLowerCase())
					)
				}
				setPost(filteredResults)
				filteredResults[previewImageIndex]?.images.map((image) => {
					postSlideImages.push(image?.image)
				})
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const handleSearch = () => {
		viewPosts(searchQuery)
	}

	const likePost = async (idpost) => {
		try {
			const data = new FormData()
			data.append('is_like', true)
			data.append('post', idpost)
			data.append('user', auth?.userInfo?.user?.id)
			// console.log('blogpost Form Data', data)
			if (auth?.userInfo?.user?.id) {
				const result = await instance.post('post-like/', data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				if (result?.status === 201) {
					console.log('post liked')
				}
				viewPosts()
			} else {
				toast.error('Login to like the post')
			}
		} catch (error) {
			console.log({ error })
		}
	}

	useEffect(() => {
		setCurrentTab('Latest')
		viewPosts(searchQuery)
	}, [searchQuery])

	const submitComment = async (e, postID) => {
		e.preventDefault()
		console.log('userId', auth?.userInfo?.user?.id)
		console.log('postID', postID)

		try {
			const data = new FormData()
			data.append('is_active', true)
			data.append('post', postID)
			data.append('comment', commentValue)
			data.append('user', auth?.userInfo?.user?.id)
			const result = await instance.post('post-comment/', data)
			if (result?.status === 201) {
				console.log('post liked')
				viewPosts()
				setCommentValue('')
			}
		} catch (error) {
			console.log({ error })
		}
	}

	const sharePost = async (postID) => {
		// console.log('userId', auth?.userInfo?.user?.id)
		// console.log('postID', postID)
		// console.log('Acess token', accessToken)

		try {
			const data = new FormData()
			data.append('is_share', true)
			data.append('post', postID)
			data.append('user', auth?.userInfo?.user?.id)

			const config = {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}

			const result = await instance.post('post-interactions/', data, config)
			if (result?.status === 201) {
				console.log('post liked')
				toast.success('Post Shared Successfully')
				viewPosts()
				setCommentValue('')
			}
		} catch (error) {
			if (error.message == 'Request failed with status code 401')
				return toast.error('Login to share the post')
			console.log(error.message)
			toast.error('Error while sharing the post. Try Again')
		}
	}

	const viewImgPosts = async () => {
		try {
			setPost([])
			const result = await instance.get('posts/')
			if (result?.status === 200) {
				let filteredResults = result?.data?.results

				filteredResults = filteredResults.filter(
					(post) => post?.images.length !== 0
				)

				setPost(filteredResults)
				filteredResults[previewImageIndex]?.images.map((image) => {
					postSlideImages.push(image?.image)
				})
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const viewVideoPosts = async () => {
		try {
			setPost([])
			const result = await instance.get('posts/')
			if (result?.status === 200) {
				let filteredResults = result?.data?.results

				filteredResults = filteredResults.filter(
					(post) => post?.videos.length !== 0
				)

				setPost(filteredResults)
				filteredResults[previewImageIndex]?.videos.map((video) => {
					postSlideVideos.push(video?.video)
				})
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	const viewLatestPosts = async () => {
		try {
			setPost([])
			const result = await instance.get('posts/?ordering=-created_at')
			if (result?.status === 200) {
				let filteredResults = result?.data?.results

				setPost(filteredResults)
				filteredResults[previewImageIndex]?.images.map((image) => {
					postSlideImages.push(image?.image)
				})
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	
	const viewTopPosts = async () => {
		try {
			setPost([])
			const result = await instance.get('posts/')
			if (result?.status === 200) {
				let filteredResults = result?.data?.results
				// Sort the filtered results based on the sum of comment_count, share_count, and like_count
				filteredResults.sort((a, b) => {
					const sumA = a.comment_count + a.share_count + a.like_count
					const sumB = b.comment_count + b.share_count + b.like_count
					return sumB - sumA
				})

				setPost(filteredResults)
				filteredResults[previewImageIndex]?.images.map((image) => {
					postSlideImages.push(image?.image)
				})
				setIsLoading(false)
			}
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}
	const viewPeople = async () => {
		try {
			const result = await instance.get('post-user/')
			console.log('peroplpde', result?.data)
			setPeopleData(result?.data)
		} catch (error) {
			setIsLoading(false)
			console.log({ error })
		}
	}

	return (
		<>
			<div className='d-flex flex-column padding-20'>
				<div className='container mb-5 '>
					<h1 className='feed-heading'> Feed </h1>
					<div className='d-flex justify-content-center row '>
						<div className='search-bar d-flex justify-content-center col-md-7'>
							<form onSubmit={(e) => e.preventDefault()}>
								<input
									type='search'
									name='search'
									placeholder='Search Here'
									style={{ backgroundColor: '#212529' }}
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
								<button
									onClick={handleSearch}
									type='submit'
									style={{ background: 'transparent' }}
								>
									<i className='bi bi-search' />
								</button>
							</form>
						</div>
						<div className='chooser col-md-7'>
							<ul className='list-inline  highlighted_underline'>
								{/* <li
									className={`${
										currentTab === 'Top' ? 'active' : ''
									} list-inline-item`}
								>
									<button
										className='bg-transparent border-0 text-white'
										onClick={() => {
											setCurrentTab('Top')
											viewTopPosts()
										}}
									>
										Top
									</button>
								</li> */}
								<li
									className={`${
										currentTab === 'Latest' ? 'tab_active' : ''
									} start_btn`}
									onClick={() => {
										setCurrentTab('Latest')
										viewLatestPosts()
									}}
								>
										Latest
								</li>
								<li
									className={`${
										currentTab === 'Photos' ? 'tab_active' : ''
									} middle_btn`}
									onClick={() => {
										setCurrentTab('Photos')
										viewImgPosts()
									}}
								>
										Photos
								</li>
								<li
									className={`${
										currentTab === 'Videos' ? 'tab_active' : ''
									} middle_btn`}
									onClick={() => {
										setCurrentTab('Videos')
										viewVideoPosts()
									}}
								>
										Video
								</li>

								<li
									className={`${
										currentTab === 'People' ? 'tab_active' : ''
									} last_btn`}
									onClick={() => {
										setCurrentTab('People')
										viewPeople()
									}}
								>
										People
								</li>
							</ul>
						</div>

						<div className='col-md-7 position-relative'>
							{isLoading && <Loader2 />}
							<div className='feed p-2'>
								{auth?.isLogin && (
									<div className=' feed-card d-flex flex-row justify-content-between align-items-center p-2 bg-dark border-0 '>
										<div className='feed-text px-2 w-100'>
											<input
												type='text'
												name='title'
												placeholder="What's happening?"
												className='feed-input text-white mt-2'
												value={postText?.title}
												onChange={handleInputChange}
												maxLength='255'
											/>
											<div className='feed-upload-container d-flex justify-content-between align-items-center'>
												<div className='upload-btn-wrapper d-flex gap-3'>
													{canUploadImage && (
														<div className='position-relative'>
															<button className='btn '>
																<i className='fa fa-image'></i>
															</button>
															<input
																style={{ width: '30px' }}
																className='post-file'
																type='file'
																id='feed-upload-img'
																name='feed-upload-img'
																accept='image/*'
																multiple
																onChange={handleImageChange}
															/>
														</div>
													)}

													{canUploadVideo && (
														<div className='position-relative'>
															<button className='btn '>
																<i className='fa fa-video'></i>
															</button>
															<input
																style={{ width: '30px' }}
																className='post-file'
																type='file'
																id='feed-upload-video'
																name='feed-upload-video'
																accept='video/*'
																onChange={handleVideoChange}
															/>
														</div>
													)}
												</div>

												<div className='post-btn'>
													{isPost ? (
														<a onClick={sendImages}>Post</a>
													) : (
														<a>Posting</a>
													)}
												</div>
											</div>
											<div>
												{previewSources.map((src, index) => (
													<img
														key={index}
														src={src}
														alt='Preview'
														style={{
															height: '20px',
															width: '20px',
															margin: '5px',
															objectFit: 'cover',
														}}
													/>
												))}
												{videoPreview && (
													<video width='50' height='50'>
														<source src={videoPreview} type='video/mp4' />
														Your browser does not support the video tag.
													</video>
												)}
											</div>
										</div>
									</div>
								)}
								{currentTab !== 'People' &&
									post?.map((item, index) => {
										return (
											<div
												className='bg-dark border-0 mt-2 feed-card'
												id={item?.id}
												element-index={index}
												key={index}
											>
												<div>
													<div className='d-flex flex-row justify-content-between align-items-center p-2 '>
														<div className='d-flex flex-row align-items-center feed-text px-2'>
															<img
																className='rounded-circle blog_user-img'
																src={
																	item?.user?.profile?.profile_picture || Avatar
																}
																width='45'
															/>
															<div className='d-flex flex-column flex-wrap ml-2 m-2'>
																<div className='d-flex flex-row gap-2'>
																	<span className='font-weight-bold text-white'>
																		{item?.user?.first_name}{' '}
																		{item?.user?.last_name}
																	</span>
																	<span className='text-white-50 time '>
																		<TimeDifference
																			createdAt={item?.created_at}
																		/>
																	</span>
																</div>
																<span className='text-white-50 time'>
																	@
																	{item?.user?.username &&
																		item.user.username.split('@')[0]}
																</span>
															</div>
														</div>
														{auth?.isLogin &&
															item?.user?.id === auth?.userInfo?.user?.id && (
																<div className='feed-icon px-2 position-relative'>
																	<i
																		className='fa fa-ellipsis-v text-white'
																		onClick={() => {
																			if (selectedPostIndex === index) {
																				setSelectedPostIndex(null)
																			} else {
																				setSelectedPostIndex(index)
																			}
																		}}
																	></i>

																	<div
																		className={`option-wrapper position-absolute ${
																			selectedPostIndex !== index
																				? 'd-none'
																				: ''
																		}`}
																	>
																		<p
																			id={item?.id}
																			onClick={delFeedPost}
																			style={{
																				marginBottom: 0,
																				cursor: 'pointer',
																			}}
																		>
																			Delete
																		</p>
																	</div>
																</div>
															)}
													</div>
												</div>
												<div
													className={
														'p-2 px-3 text-white feed-content ' +
														(currentTab === 'Photos' || currentTab === 'Videos'
															? 'd-none'
															: '')
													}
												>
													<p className='feed-title'></p>
													<span>
														{item?.title.includes('Shared post:') ? (
															<>
																<strong>Shared Post:</strong>
																{item?.title.replace('Shared post:', '')}
															</>
														) : (
															item?.title
														)}
													</span>
												</div>
												{item?.images && (
													<div className='post'>
														{item?.images.length === 1 ? (
															<img
																src={item?.images[0]?.image}
																onClick={openImageViewer}
																key={98}
																className='single-image'
																alt='post-img'
																element-index={0}
															/>
														) : (
															<div
																className={`grid grid-${item?.images.length} justify-content-center`}
															>
																{item?.images
																	?.slice(0, 4)
																	?.map((image, index) => {
																		return (
																			<div
																				className={`image-overlays multiple-image image-${item?.images.length}`}
																				key={index}
																			>
																				<img
																					src={image?.image}
																					onClick={openImageViewer}
																					id={image?.id}
																					key={index}
																					alt='post-img'
																					element-index={index}
																					style={{
																						width: '100%',
																						height: '100%',
																						objectFit: 'cover',
																					}}
																				/>
																				{item?.images.length > 4 &&
																					index === 3 && (
																						<div
																							className={`overlay overlay-${
																								index + 1
																							}`}
																							element-index={index}
																							onClick={openImageViewer}
																						>
																							+ {item?.images.length - 4}
																						</div>
																					)}
																			</div>
																		)
																	})}
																{isViewerOpen && (
																	<ImageViewer
																		src={postSlideImages}
																		currentIndex={currentImage}
																		onClose={closeImageViewer}
																		disableScroll={false}
																		backgroundStyle={{
																			backgroundColor: 'rgba(0,0,0,0.9)',
																		}}
																		closeOnClickOutside={true}
																	/>
																)}
															</div>
														)}
													</div>
												)}
												{item?.videos.length === 1 && (
													<div className='post'>
														<video
															className='single-video'
															src={item?.videos[0]?.video}
															controls
														/>
													</div>
												)}

												<div className='feed-social  socials p-4 py-3 pb-0'>
													<div className='d-flex flex-row'>
														<div>
															<i
																className={`fa fa-heart ${
																	item?.post_like_users.some(
																		(post_like_user) =>
																			post_like_user.user ===
																			auth?.userInfo?.user?.id
																	)
																		? 'post-liked'
																		: ''
																}`}
																onClick={() => likePost(item?.id)}
															></i>
														</div>
														<div>
															<p>{item?.like_count}</p>
														</div>
													</div>
													<div className='d-flex flex-row'>
														<div>
															<i
																className='fa fa-comments'
																onClick={() => {
																	if (setIsShowComment === index) {
																		setIsShowComment(null)
																	} else {
																		setIsShowComment(index)
																	}
																}}
															></i>
														</div>
														<div>
															<p>{item?.comment_count}</p>
														</div>
													</div>
													<div className='d-flex flex-row'>
														<div>
															<i
																className='fa fa-share'
																onClick={() => sharePost(item?.id)}
															></i>
														</div>
														<div>
															<p>{item?.share_count}</p>
														</div>
													</div>
												</div>

												{auth?.isLogin && (
													<div
														className={`feed-social socials p-4 py-3  ${
															isShowComment !== index ? 'd-none' : ''
														}`}
													>
														<form
															onSubmit={(e) => submitComment(e, item?.id)}
															action='#'
															style={{
																flex: 1,
																position: 'relative',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
															}}
														>
															<input
																required
																type='text'
																name='Comment'
																className='comment-input'
																placeholder='comment Here'
																value={commentValue}
																onChange={(e) =>
																	setCommentValue(e.target.value)
																}
															/>
															<button
																type='submit'
																style={{
																	background: 'transparent',
																	border: 'none',
																	position: 'absolute',
																	right: '0',
																}}
															>
																<i className='bi bi-send' />
															</button>
														</form>
													</div>
												)}

												{item?.comments.length !== 0 && (
													<div
														className={`socials p-4 py-1 ${
															isShowComment !== index ? 'd-none' : ''
														}`}
													>
														{item?.comments.reverse().map((comment, index) => (
															<div
																key={index}
																className='d-flex comment-box text-white my-3'
															>
																<div className='comment_user-name'>
																	<img
																		src={
																			comment?.user?.profile?.profile_picture ||
																			Avatar
																		}
																		width={25}
																		className='comment_avatar'
																		alt='User Avatar'
																	/>
																	{comment?.user?.first_name}{' '}
																	{comment?.user?.last_name}
																	<div className='comment_content'>
																		{comment.comment}
																	</div>
																</div>
															</div>
														))}
													</div>
												)}
											</div>
										)
									})}

								{currentTab === 'People' &&
									peopleData &&
									peopleData?.map((item, index) => {
										return (
											<div
												className='bg-dark border-0 mt-2 feed-card pb-0'
												id={item?.id}
												element-index={index}
												key={index}
											>
												<div>
													<div className='d-flex flex-row justify-content-between align-items-center p-2 '>
														<div className='d-flex flex-row align-items-center feed-text px-2'>
															<img
																className='rounded-circle blog_user-img'
																src={item?.profile?.profile_picture || Avatar}
																width='45'
															/>
															<div className='d-flex flex-column flex-wrap ml-2 m-2'>
																<div className='d-flex flex-row gap-2'>
																	<span className='font-weight-bold text-white'>
																		{item.first_name} {item.last_name}
																	</span>
																</div>
																<span className='text-white-50 time'>
																	@
																	{item?.username &&
																		item.username.split('@')[0]}
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										)
									})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BlogPage
