/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-globals */
import Breadcrumb from "components/common/Breadcrumb";
import Footer from "components/common/Footer";
import React, { useState, useEffect, useRef } from "react";
import { instance } from "index";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SRLWrapper } from "simple-react-lightbox";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader2 from "components/common/Loader2";

const Event = () => {
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isImagePreviewMode, setImagePreviewMode] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const swiperRef = useRef(null);
  const [swiper, setSwiper] = useState(null); // Store the Swiper instance
  const [eventImages, setEventImages] = useState([]);

  const history = useHistory();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const portfolioSlider = {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // autoplay: {
    //   delay: 5000,
    // },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30,
      },

      // when window width is >= 640px
      768: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      // when window width is >= 992px
      992: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      // when window width is >= 1400px
      1400: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
    },
  };

  const getAllEvents = async () => {
    try {
      const result = await instance.get("events-plans/");
      if (result?.status === 200) {
        setIsLoading(false);
        setEvent(result?.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const getDetail = (id) => {
    history.push({
      pathname: "/eventDetail",
      state: { message: id },
    });
    scrollTop();
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const handleImageClick = (item) => {
    setSelectedImage(item);
    setImagePreviewMode(true);
    if (swiper) {
      swiper.allowTouchMove = false;
    }
  };

  const exitImagePreview = () => {
    setImagePreviewMode(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    setEventImages(
      event?.results?.filter((item) => new Date(item?.date) < new Date())
    );
  }, [event]);

  return (
    <>
      <Breadcrumb pageName="Events" />

      <section className="career__wrapper mt-0 padding-20">
        <div className="container">
          <div className="row">
            <div className="col-12 main-heading text-center mt-5">
              <p className="main-title" style={{ color: "#F50808" }}>
                Bugdev <span className="text-white">Events</span>
              </p>
            </div>
          </div>

          <div className="container position-relative">
            {isLoading && <Loader2 />}
            <div className="row mt-3">
              <div className="col-12">
                <div className="comming_Events">
                  <p>Upcomming Events</p>
                </div>
              </div>
              <section className="pt-2 pb-2 position-relative">
                <Slider {...settings}>
                  {event?.results
                    ?.filter((item) => new Date(item?.date) >= new Date())
                    ?.map((item) => (
                      <div className="event-card" key={item?.id}>
                        <div className="card-top">
                          <img
                            src={item?.images[0]?.image}
                            style={{ objectFit: "fill" }}
                            alt={"images"}
                          />
                          <h1>{item?.name}</h1>
                        </div>
                        <div className="card-bottom">
                          <span className="category">
                            {item?.description?.slice(0, 100)}{" "}
                            {item?.description?.length > 100 ? "..." : ""}
                          </span>
                        </div>
                        <div className="btn-container ">
                          <button
                            className="event-btn"
                            onClick={() => getDetail(item?.id)}
                          >
                            Read more
                          </button>
                          <i>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/arrow-circle.png"
                              }
                              alt="images"
                              className="event-img"
                            />
                          </i>
                        </div>
                      </div>
                    ))}
                </Slider>
              </section>
            </div>
            <SRLWrapper
              options={{
                buttons: { showNextButton: false, showPrevButton: false },
              }}
            >
              <div className="portfolio-area sec-mar-top">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-7 col-xl-5">
                      <div className="title">
                        <span>Events</span>
                        <h2>Past Events</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper portfolio-slider"
                  style={{ padding: "60px 0 50px 0" }}
                >
                  <Swiper
                    onSwiper={(swiper) => setSwiper(swiper)}
                    ref={swiperRef}
                    {...portfolioSlider}
                    allowTouchMove={false}
                    loop={false}
                    pagination={{
                      type: "bullets",
                      clickable: true,
                    }}
                    // navigation={{
                    //   nextEl: '.swiper-button-next',
                    //   prevEl: '.swiper-button-prev',
                    // }}
                    className="swiper-wrapper"
                  >
                    {eventImages?.map((item) => (
                      <SwiperSlide className="swiper-slide" key={item?.id}>
                        <div
                          className="carousel slide mt-5"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                        >
                          <div className="carousel-inner">
                            <div className="carousel-item active">
                              <div className="d-flex flex-lg-row flex-md-column flex-sm-column flex-xs-column flex-column justify-content-center align-items-center">
                                <div className="card-body col-sm-12 col-md-12 col-lg-5">
                                  <img
                                    src={item?.images[0].image}
                                    alt="Event-Image"
                                    className="img-fluid"
                                    style={{
                                      width: "400px",
                                      height: "300px",
                                      objectFit: "fill",
                                      borderRadius: "20px",
                                    }}
                                    onClick={() => handleImageClick(item)}
                                  />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-7 ">
                                  <div className="card-body d-md-block ">
                                    <h5 className="text-white">{item?.name}</h5>
                                    <p className="text-white">
                                      {item?.description}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="swiper-pagination" />
                    <div className="swiper-button-next" />
                    <div className="swiper-button-prev" />
                </div>
              </div>
            </SRLWrapper>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Event;
