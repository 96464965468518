import { useEffect, useMemo, useState } from "react";
import { timeTable } from "contants/data";
import Table from "components/common/table/Table";
import { AiFillProject } from "react-icons/ai";
import { MdComputer } from "react-icons/md";
import { BsNewspaper } from "react-icons/bs";
import { Link, useRouteMatch } from "react-router-dom";
import { instance } from "index";
import { useSelector } from "react-redux";
import Loader3 from "components/common/Loader3";

const Dashbord = () => {
  const [empSummary, setEmpSummary] = useState([]);
  const { path } = useRouteMatch();
  const [application, setApplication] = useState();
  const [events, setEvents] = useState();
  const { access } = useSelector((state) => state.auth.userInfo);
  const [isLoading, setIsLoading] = useState(true);

  const getApplication = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get("job-application/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        setApplication(result?.data?.results?.length);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const getEvents = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get("events-plans/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        setEvents(result?.data?.results?.length);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const employeeData = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get("user-role/", {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 200) {
        setIsLoading(false);
        setEmpSummary(result?.data?.results);
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };
  useEffect(() => {
    getApplication();
    employeeData();
  }, []);

  useEffect(() => {
    getEvents();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        width: 300,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div key={cellProps.row.original?.id}>
              {`${cellProps.row.original.first_name} ${cellProps.row.original.last_name}`}
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        width: 400,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Status",
        accessor: "profile.employee_status",
        filterable: false,
        width: 400,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cell) => {
          return (
            <div
              className={`dotDiv d-flex justify-content-center align-items-center w-100`}
            >
              <span className={cell?.value?.toLowerCase()} />
              {cell?.value}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <section className="dashboard__task__wrapper">
        <div className="container p-4">
          <div className="row mb-5 ">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <p className="title">Dashboard</p>
            </div>
          </div>
          {isLoading ? (
            <Loader3 />
          ) : (
            <>
              <div className="row mb-5">
                <div className="col-md-4 mb-3">
                  <Link to={`${path}/job-application`}>
                    <div className="dashboardCard">
                      <div className="title">
                        <p className="">
                          <AiFillProject />
                        </p>
                        {/* <button>
										<BsThreeDotsVertical />
									</button> */}
                      </div>
                      <div className="contentCard">
                        {/* <p>{application}</p> */}
                        <h6>{application} Applications</h6>
                        <p style={{ fontSize: "14px" }}>
                          View All Applications
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 mb-3">
                  <Link to={`${path}/events`}>
                    <div className="dashboardCard">
                      <div className="title">
                        <p className="">
                          <BsNewspaper />
                        </p>
                        {/* <button>
										<BsThreeDotsVertical />
									</button> */}
                      </div>
                      <div className="contentCard">
                        <h6>{events} Events</h6>
                        <p style={{ fontSize: "14px" }}>
                          View all Company Events
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 mb-3">
                  <Link to={`${path}/attendance`}>
                    <div className="dashboardCard">
                      <div className="title">
                        <p className="">
                          <MdComputer />
                        </p>
                        {/* <button>
										<BsThreeDotsVertical />
									</button> */}
                      </div>
                      <div className="contentCard">
                        <h6>Attendance</h6>
                        <p style={{ fontSize: "14px" }}>
                          View all Employ Attendance
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div
                    className="card  table-card overflow-auto"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="sidebar-search d-flex justify-content-between mb-3">
                      <h3 className="title">Employee Summery</h3>
                    </div>
                    <Table
                      tableStyles={{ tableLayout: "fixed" }}
                      columns={columns}
                      initialState={{
                        columnVisibility: { conversion: false },
                      }}
                      data={empSummary}
                      total={empSummary?.length}
                      customPageSize={1}
                      divclassName="table-responsive mb-1"
                      tableclassName="align-middle table-nowrap"
                      theadclassName="table-light text-muted"
                      getRowId={(row) => row?.id}
                      fetchData={() => console.log("fetchData")}
                      pageCount={3}
                      currentPage={1}
                      isNextPage={2}
                      isPrevPage={null}
                      nextHandler={() => console.log("Next")}
                      prevHandler={() => console.log("Prev")}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Dashbord;
