import Loader from "components/common/Loader";
import Table from "components/common/table/Table";
import { instance } from "index";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import Loader3 from "components/common/Loader3";

export default function Jobs() {
  const { auth } = useSelector((state) => state);
  const history = useHistory();
  const { access } = useSelector((state) => state.auth.userInfo);
  const [isClockIn, setIsClockIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [modal, setModal] = React.useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [getId, setGetId] = useState("");
  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    location: "",
    type: "",
  });

  const toggle = () => {
    setModal(!modal);
    setIsCheck(true);
    setFormValues({
      title: "",
      description: "",
      location: "",
      type: "",
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Job Title",
        accessor: "title",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Job Location",
        accessor: "location",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Job Type",
        accessor: "type",
        filterable: false,
        width: 250,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
      },
      {
        Header: "Posted date",
        accessor: "created_at",
        filterable: false,
        width: 250,
        Cell: (cell) =>
          cell?.value && cell?.value !== null && cell?.value !== ""
            ? moment.utc(cell?.value?.toString()).format("MM/DD/YYYY")
            : "",
      },
      {
        Header: "Action",
        filterable: false,
        width: 350,
        cellStyle: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          wordWrap: "break-word",
        },
        Cell: (cellProps) => {
          return (
            <div className="d-flex align-items-center justify-content-center gap-2">
              <>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => getDelete(cellProps.row.original.id)}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/delete-icon.png"}
                    alt="images"
                    style={{ cursor: "pointer" }}
                  />
                </button>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => editToggle(cellProps)}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/edit-icon.png"}
                    alt="images"
                    style={{ cursor: "pointer" }}
                  />
                </button>
              </>

              <button
                className="border-0 bg-transparent"
                onClick={() => getRequestItem(cellProps.row.original.id)}
              >
                <i
                  className="fa fa-eye"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "22px",
                  }}
                ></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const getJobs = async () => {
    setIsLoading(true);
    try {
      const result = await instance.get(`career/`);
      if (result?.status === 200) {
        // Assuming the API response contains a 'results' property that is an array of job objects
        setJobs(result?.data.results);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false); // Set isLoading to false in case of an error
      console.log({ error });
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  const sendRequest = async () => {
    if (!formValues?.title || !formValues?.description) {
      return toast.error("All fields are required!");
    }
    try {
      const data = {
        ...formValues,
        user: auth?.userInfo?.user?.id,
        status: true,
        is_active: true,
      };
      const result = await instance.post("career/", data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${access}`,
        },
      });
      if (result?.status === 201) {
        toast.success("Request sent successfully!");
        setModal(false);
        getJobs();
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getDelete = async (itemId) => {
    setIsLoading(true);
    try {
      const result = await instance.delete(`career/${itemId}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
      setIsLoading(false);
      if (result?.status === 204) {
        setIsLoading(false);
        getJobs();
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  const getRequestItem = async (id) => {
    history.push({
      pathname: "/hr-dashboard/job-detail",
      state: { message: id },
    });
  };
  const getEdit = async () => {
    try {
      const data = {
        ...formValues,
        user: auth?.userInfo?.user?.id,
        status: true,
        is_active: true,
      };
      const result = await instance.patch(
        `career/${getId?.row?.original?.id}/`,
        data,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${access}`,
          },
        }
      );

      if (result?.status === 200) {
        toast.success("Request sent sucessfully!");
        setModal(false);
        getJobs();
        setFormValues({
          ...formValues,
          title: "",
          description: "",
          location: "",
          type: "",
          status: false,
          is_active: false,
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const editToggle = (idData) => {
    setFormValues({
      ...formValues,
      description: idData?.row?.original?.description,
      title: idData?.row?.original?.title,
      type: idData?.row?.original.type,
      location: idData?.row?.original.location,
    });
    setGetId(idData);
    setModal(!modal);
    setIsCheck(false);
  };

  return (
    <>
      <div className="myTime dashboard_header mb-5 mx-5">
        <h6 className="text-white tableHeading">Jobs Dashbord</h6>
        <div className="cmn-btn">
          <button className="mb-0 mt-1" onClick={toggle}>
            Post Job
          </button>
        </div>
      </div>
      {isCheck ? (
        <div style={{ display: "block" }}>
          <Modal
            isOpen={modal}
            toggle={() => setModal(true)}
            className="addtask-popups"
          >
            <ModalBody>
              <h3 className="text-center text-white">Post Job</h3>
              <div className="addtask-body">
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Title:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.title}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    className="form-control"
                    value={formValues?.description}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                    style={{ height: "150px", borderRadius: "20px" }}
                    id="message-text"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Type:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.type}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, type: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Location:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.location}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, location: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="cmn-btn">
                <button onClick={toggle}>Close</button>
              </div>
              <div className="cmn-btn">
                <button onClick={sendRequest}>Submit</button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      ) : (
        <div style={{ display: "block" }}>
          <Modal
            isOpen={modal}
            toggle={() => setModal(true)}
            className="addtask-popups"
          >
            <ModalBody>
              <h3 className="text-center text-white">Edit Your Job</h3>
              <div className="addtask-body">
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Title:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.title}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message-text" className="col-form-label">
                    Description:
                  </label>
                  <textarea
                    className="form-control"
                    value={formValues?.description}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        description: e.target.value,
                      })
                    }
                    style={{ height: "150px", borderRadius: "20px" }}
                    id="message-text"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Type:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.type}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, type: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Location:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={formValues?.location}
                    style={{ borderRadius: "20px", height: "3rem" }}
                    onChange={(e) =>
                      setFormValues({ ...formValues, location: e.target.value })
                    }
                    id="recipient-name"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="cmn-btn">
                <button onClick={toggle}>Close</button>
              </div>
              <div className="cmn-btn">
                <button onClick={getEdit}>Submit</button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
      {isLoading ? (
        <Loader3 />
      ) : (
        <div className="row">
          <div className="col-12">
            {jobs.length > 0 ? (
              <Table
                tableStyles={{ tableLayout: "fixed" }}
                columns={columns}
                initialState={{
                  columnVisibility: { conversion: false },
                }}
                loading={isLoading}
                data={jobs}
                total={jobs?.results?.length}
                customPageSize={1}
                divclassName="table-responsive mb-1"
                tableclassName="align-middle table-nowrap"
                theadclassName="table-light text-muted"
                getRowId={(row, relativeIndex, parent) => row?.id}
                fetchData={() => console.log("fetchData")}
                pageCount={3}
                currentPage={1}
                isNextPage={2}
                isPrevPage={null}
                nextHandler={() => console.log("Next")}
                prevHandler={() => console.log("Prev")}
              />
            ) : (
              <p>No jobs available.</p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
