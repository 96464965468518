import React, { useState, useEffect } from 'react'
import { icons } from '../../../contants'
import { instance } from 'index'
import { useSelector, useDispatch } from 'react-redux'
import { toggleAuth } from 'redux/auth/authSlice'
import { toast } from 'react-toastify'
import BankDetail from '../dashboard/hrDashboard/pages/userDetail/BankDetail'
import Aside from 'components/common/Aside'
import SettingDetail from './SettingDetail'

const Setting = () => {
	const [profileData, setProfileData] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const { auth } = useSelector((state) => state)
	const handleDispacth = useDispatch()

	const [imageUpload, setImageUpload] = useState({})

	const [isShow, setIsShow] = useState({
		BankDetail: false,
		SettingDetail: true,
	})

	const getDetail = async (userId) => {
		setIsLoading(true)
		try {
			const result = await instance.get(`profiles/`,{
				headers: {
				  Authorization: `Bearer ${auth?.userInfo?.access}`, // Replace with your API key
				},
			  })
			if (result?.status === 200) {
				setIsLoading(false)
				const profileData = result?.data.results.filter(
					(item) => item.user.id === userId
				)
				setProfileData(profileData)
			}
		} catch (error) {
			console.error(error)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (auth?.userInfo?.user?.id) {
			getDetail(auth?.userInfo?.user?.id)
		}
	}, [])

	const [userData, setUserData] = useState({
		first_name: '',
		last_name: '',
		gender: '',
		phone_no: '',
		date_of_birth: '',
		email: '',
		profile_id: '',
		profile_picture: '',
		account_iban: '',
		account_holder_name: '',
		bank_name: '',
		city: '',
	})

	useEffect(() => {
		if (profileData && profileData.length > 0) {
			setUserData({
				first_name: profileData[0]?.user?.first_name,
				last_name: profileData[0]?.user?.last_name,
				email: profileData[0]?.user?.email,
				gender: profileData[0]?.gender,
				phone_no: profileData[0]?.phone_no,
				date_of_birth: profileData[0]?.date_of_birth,
				profile_picture: profileData[0]?.profile_picture,
				profile_id: profileData[0]?.id,
				account_iban: profileData[0]?.account_iban,
				account_holder_name: profileData[0]?.account_holder_name,
				bank_name: profileData[0]?.bank_name,
				city: profileData[0]?.city,
			})
		}
	}, [profileData])

	const handleInputChange = (event) => {
		const { name, value } = event.target
		setUserData((prev) => {
			return { ...prev, [name]: value }
		})
	}

	const updateUserRole = async () => {
		try {
			const result = await instance.patch(
				`user-role/${auth?.userInfo?.user?.id}/`,
				{
					first_name: userData?.first_name,
					last_name: userData?.last_name,
					email: userData?.email,
				}
			)

			if (result?.status === 200) {
				updateUserProfile()
			}
		} catch (error) {
			toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const updateUserProfile = async () => {
		try {
			const requestBody = {
				gender: userData?.gender,
				date_of_birth: userData?.date_of_birth,
				phone_no: userData?.phone_no,
				user: auth?.userInfo?.user?.id,
			}

			if (imageUpload) {
				requestBody.profile_picture = imageUpload
			}

			const result = await instance.patch(
				`profiles/${userData?.profile_id}/`,
				requestBody,
				{
					headers: {
						'content-type': 'multipart/form-data',
					},
				}
			)

			if (result?.status === 200) {
				toast.success('Data Upadted Sucessfully!')
			}
		} catch (error) {
			toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const handleUpdate = () => {
		updateUserRole()
	}

	const handleImageChange = (event) => {
		const file = event.target.files[0]
		setImageUpload(file)
		const reader = new FileReader()

		reader.onload = () => {
			setUserData((prevUserData) => ({
				...prevUserData,
				profile_picture: reader.result,
			}))
		}

		if (file) {
			reader.readAsDataURL(file)
		}
	}

	const updateUserBankProfile = async () => {
		try {
			const requestBody = {
				user: auth?.userInfo?.user?.id,
				city: userData?.city,
				account_iban: userData?.account_iban,
				account_holder_name: userData?.account_holder_name,
				bank_name: userData?.bank_name,
			}

			console.log('requestBody', requestBody)
			const result = await instance.patch(
				`profiles/${userData?.profile_id}/`,
				requestBody,
				{
					headers: {
						'content-type': 'application/json',
					},
				}
			)

			if (result?.status === 200) {
				toast.success('Data Upadted Sucessfully!')
				console.log('userDataUpdated', result?.data)
			}
		} catch (error) {
			toast.error('Oops! Error While Updating Data')
			console.log({ error })
		}
	}

	const handleBankUpdate = () => {
		updateUserBankProfile()
	}
	return (
		<>
			<section className='setting__wrapper'>
				{profileData && (
					<div className='container'>
						<div className='row mx-2'>
							<Aside
								handleDispacth={handleDispacth}
								userData={userData}
								icons={icons}
								handleImageChange={handleImageChange}
								toggleAuth={toggleAuth}
								isShow={isShow}
								setIsShow={setIsShow}
							/>
							{isShow?.SettingDetail && (
								<SettingDetail
									handleUpdate={handleUpdate}
									userData={userData}
									handleInputChange={handleInputChange}
								/>
							)}
							{isShow?.BankDetail && (
								<BankDetail
									handleBankUpdate={handleBankUpdate}
									userData={userData}
									handleInputChange={handleInputChange}
								/>
							)}
						</div>
					</div>
				)}
			</section>
		</>
	)
}
export default Setting